import React, { Component } from 'react';
import { Link,useParams, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,rehabURL,mediaUrl} from'../Config/Config';
import axios from 'axios';
import MyEditor from '../Membership/MyEditor';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Scrollbars } from 'react-custom-scrollbars';

/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		super(props);	
	    const rehabid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
			rehabid: rehabid,
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			password:'',
			status:'',
			changepassword:'',
			user_confirm_password: '',
			 new_pass_type: 'password',
			confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			selectedDomainOption:'',
			selectedDomainvalue:'',
			maxCharacterLimit : 10,
			selectedYear:'',
			selectedYearvalue:'',
			street:'',
			city:'',
			state:'',
			country:'',
			postalcode:'',
			amenitynamelist:[],
			selectedcategoryvalue:'',
			selectedamenityid:'',
			editid:0,
			image_preview:'',
			image_name:'',
			image:'',
			old_image:'',
			fileExtension:'',
			description:'',
			galleries:[],
			updatedImages:'',
			images:'',
			setImages:'',
			user_image: '',	
			imagepath: '',	
			selected_image: '',	
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No',

		};

		
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );

    }

	onEditorChange( evt ) {
		
		console.log(evt, 'CKE')

	   var callfunc = this;
	   setTimeout(function () {
		this.setState( {
            description: evt
        } );
		}.bind(this),1000);
	
   }
	listloaddata=()=>{

		var qs = require('qs');
		axios.get(apiUrl+"rehab/listdetail?id="+this.state.rehabid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.rehablist;
				this.setState({galleries:res.data.galleries});
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			// console.log(res.data.galleries,"res.data.galleriesres.data.galleries")
			           						
			            this.setState({username:formdata.username});						
			            this.setState({rehabname:formdata.center_name});						
			            this.setState({firstname:formdata.name});						
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.phone});
						this.setState({street:formdata.center_address});
						this.setState({city:formdata.city});
						this.setState({state:formdata.state});
						this.setState({country:formdata.country});
						this.setState({postalcode:formdata.zipcode});
						this.setState({rehabid: formdata.rehab_id});
						this.setState({selectedYearvalue: formdata.year_of_started});
						this.setState({selectedcategoryvalue:formdata.amenityids});
			            this.setState({selectedamenityid:formdata.amenityids});
						this.setState({editid:1,image_preview:formdata.profile_image,old_image:formdata.profile_image,description:formdata.description})
						var postObject = {
							id: res.data.rehablist.domain_id,			
						};
						if(formdata.year_of_started !== ''){
							this.setState({selectedYear:{value: formdata.year_of_started, label: formdata.year_of_started}});
						}else{
							this.setState({selectedYear:''});
						}
						axios.post(apiUrl+"user/domainlist",qs.stringify(postObject)).then(res => {
							if(res.data.status == "success"){					
								let domaindata = res.data.domainlist;
								this.setState({selectedDomainOption:{value: domaindata[0]['domain_id'], label: domaindata[0]['domain_name']}});					
								this.setState({selectedDomainvalue:domaindata[0]['domain_id']});					
							}
						});
			}
			
		}); 
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'rehab/getamenitynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						amenitynamelist:res.data.amenitynamelist
					});
				
				}else{
				}
			});

	}
	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		reader.readAsDataURL(file);
	}
	isUSZipCode(zipCode) {
		// Regular expression pattern for validating US ZIP codes
		const zipCodePattern = /^\d{5}(-\d{4})?$/;
		return /^\d{5}(-\d{4})?$/.test(zipCode) || /^\d{9}$/.test(zipCode);
	}
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
    }
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
	this.listloaddata()
    }

	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
				var selectedamenitys='';
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				const selectedamenityids = formPayload.selectedamenityid;
				var elements=[];
				let catList  = ''
				if(typeof selectedamenityids === 'object' && selectedamenityids!=='' && typeof selectedamenityids!==undefined && selectedamenityids!==null) {
					catList = selectedamenityids.map((number) =>
					elements.push(number.value)
				);
				selectedamenitys=elements.toString();

				}else{
					selectedamenitys=selectedamenityids;

				}

				var postObject = {
			      
					admin_id : localStorage.getItem("admin_id"),
					rehabid       : formPayload.rehabid,
					domain_id       : formPayload.selectedDomainvalue,
					name    : formPayload.firstname,
					startedYear    : formPayload.selectedYearvalue,
					rehabname     : formPayload.rehabname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					street      : formPayload.street,
					city      : formPayload.city,
					state      : formPayload.state,
					country      : formPayload.country,
					postalcode      : formPayload.postalcode,
					description      : formPayload.description,
					status       :  status,
					amenityids:selectedamenitys,
					editid:  this.state.editid,
					old_image:  this.state.old_image,
					image: this.state.editid===0 ? formPayload.image:'',	

				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}	
				var galleryimg = this.state.galleryimg;

				var filesdata = this.state.user_image;
				if(galleryimg==2){
				for (var i in filesdata) {
					formData.append('multiple_images[]',filesdata[i])
				}
			}
										var filesdata;
								
									filesdata = this.state.selectedimages;	
									if(galleryimg==1){
									for (var i in filesdata) {
										formData.append('images[]',filesdata[i])
									}
								}
				axios.post(apiUrl+"rehab/edit",formData).then(res => {
					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.rehablist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/user');
								}
								.bind(this),
								3000
								);
					} else {
						
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {galleryimg,selectedimages,user_image,rehabname,firstname, email, phoneno,selectedDomainvalue,postalcode,image,description} = this.state;
      	//let formIsValid = true;
		  var galleryhubCount =  (selectedimages)?selectedimages.length:0;
		  var selectedlocalCount =  (user_image && user_image.length)?user_image.length:0;
		  const nameRegex = /^[A-Za-z\s.]+$/;
		  const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		  let maxSize = 1000000;//1 mb
  
		let errors = 0;
		if (user_image) {
			
			
			for (let i = 0; i < user_image.length; i++) 
			{

			   const file = user_image[i];
			   const filename = file.name;
			   console.log(filename,"filename")

			   // if (file.size > maxSize) {
			   // 	errors++;
			   
			   // $('.erroruser_image').html('<span class="errorspan">'+filename+'File is too large. Max size is 1MB</span>');
			   // continue;
			   // }
	   
			   if (!allowedTypes.includes(file.type)) {
				   errors++;
			   
			   $('.erroruser_image').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

			   continue;
			   }
		   }
	  }
	  
	  if(galleryimg==1&&galleryhubCount > 5){
					
		errors++;
		$('.erroruser_image').html('<span class="errorspan">You can only upload up to 5 images</span>');
		//fileErrors.push('You can only upload up to 10 images.');
	
}else{
	$('.erroruser_image').html('<span class="errorspan"></span>');
}
if(galleryimg==2&&selectedlocalCount > 5){
		
	errors++;
	$('.erroruser_image').html('<span class="errorspan">You can only upload up to 5 images</span>');
	//fileErrors.push('You can only upload up to 10 images.');

}else{
$('.erroruser_image').html('<span class="errorspan"></span>');
}
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}
		if (!description) {
			errors++;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if (!rehabname) {
			errors++;
			$('.errorrehabname').html('<span class="errorspan">Please fill the field</span>');
		}else{
				$('.errorrehabname').html('');
		}
		
		if (this.state.editid==0) {
			if (!image) {
				
				errors++;
				$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
					}else{
						$('.errorfile').html('');
					}
					if (image) {		
			if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
				
				errors++;
					 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
				   
			  }else{
				$('.errorfile').html('');
			  }
			}
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
			if (!selectedDomainvalue) {
				errors++;
				$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		   }else if(selectedDomainvalue){
				$('.errorassign_domain').html('');
			}

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Contact is required</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

				if(postalcode){
					if(this.isUSZipCode(postalcode)){
						$('.errorpostalcode').html('');
					}else{
						errors++;
						$('.errorpostalcode').html('Please enter valid postal code');
					}
				}
		
		


		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    
	  handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}

handleYearChange = selOption => {
	this.setState({selectedYear:selOption})
		this.setState({  selectedYearvalue : selOption.value});
};
handleProductCategoryChange = (event) => {  
	console.log(event,'onchange')
	this.setState({selectedamenityid : event}, function() {
	   const user_list = this.state.selectedamenityid;
	});
}
 handleUpload = (event) => {
    const newImage = URL.createObjectURL(event.target.files[0]);
	this.setState({images:newImage});
  };
 handleDelete = (index) => {
	console.log(index,"indexindexindexindex")
    // const updatedImages = [...images];
	const updatedImages = this.state.images
    // updatedImages.splice(index, 1);
    this.setState({images:updatedImages});
  };
  confirmPopup = (id,imagepath,selected_image) => {
	this.setState({selectid: id,imagepath:imagepath,selected_image:selected_image})	

	$('.confirm-action').addClass('show');		
  }
  Confirmstatus = (confirmstatus) => {
	if(confirmstatus == 'yes'){
	  this.deleteimage();
	  $('.confirm-action').removeClass('show');
	}else{
	  $('.confirm-action').removeClass('show');	
	}
	  
  }
  deleteimage(){
  
	
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          rehab_galerry_id  :this.state.selectid,
          rehab_id  :this.state.rehabid,
          imagepath  :this.state.imagepath,
          selected_image  :this.state.selected_image,
		 
		 
        };
        axios.post(apiUrl+"rehab/deleteimage",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
              this.setState({galleries:res.data.galleries})
              $('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}
uploadMultipleFiles = (e) =>{
	this.fileObj = [];

        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
		// this.setState({ user_image: this.imageArray })
		this.setState({ user_image: this.imageArray,galleryimg:2,selectedimages:[] })		
	
    }


     removeImagebyindex = (indexvalue) => {
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		
		
	}
	Checkgallery = (indexs,value) =>{
		var imageArr = [...this.state.selectedimages];
			var imgcount =	imageArr!=''?imageArr.length:0;
		const index = imageArr.findIndex(images => images === indexs);
		if(imgcount>=5){
			$('.showconvey').addClass('show');	
		}else{
			if(index > -1) {
				imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
			} else {
			imageArr.push(value);
			}
			console.log(imageArr,"imageArrimageArr")
			// this.setState({selectedimages: imageArr});
			this.setState({selectedimages: imageArr,galleryimg:1,user_image:[],fileArray:[]});
		}
		
	
	}
	 getGalleryimages() {
	  var imageArr = this.state.galleryimagelist;
	  if(imageArr!== undefined && imageArr!== null){
		if(Object.keys(imageArr).length > 0) {
		   const imageDetails = imageArr.map((image, Index) => {
				 var checked = false;
			  return (
						  <div className="asp-col" key={Index}>  
							  <input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
							  <label>
							  <img src={image['url']} alt="" className="" />
							  </label>
						  </div>
					   );
	  
	   });
		return imageDetails;
	   }
	  } else {
		return (<div className="">No images found</div>);
	  }
	}
	opengalleryImages = () => {
		  axios.get(apiUrl+'rehab/getimagelist?rehabid='+this.state.rehabid)
		  .then(res => {
			  if(res.data.status == 'success'){
				  this.setState({galleryimagelist: res.data.imagelist})
			  }else{
				 
			  }
		  });
		  this.setState(prevState => ({
			  enableuploadgalley: !prevState.enableuploadgalley
		  }));
	 }
  
		galleryClose = () => {
  
			 if(Object.keys(this.state.selectedimages).length > 0){
			 }
			 this.setState(prevState => ({
			  enableuploadgalley: !prevState.enableuploadgalley
		  }));
		  $('.cargallery-popup').removeClass('active');
	 }
	 Confirmstatus = (confirmstatus) => {
		if(confirmstatus == 'no'){
		  $('.showconvey').removeClass('show');
		  $('.cargallery-popup').removeClass('active');
	
		}else{
			setTimeout(
				function() {
			   
					$('.showconvey').removeClass('show');
					$('.cargallery-popup').removeClass('active');
	
				}
				.bind(this),
				3000
				);
		}
		  
	  }
	  removeImage(index) {
		const updatedImages = [...this.state.selectedimages];
		updatedImages.splice(index, 1);
		this.setState({ selectedimages: updatedImages });
	  }
  render() {

  	const {galleries,selectedOption,selectedDomainOption,selectedYear,amenitynamelist,selectedcategoryvalue,selectedimages} = this.state;
let image_path =  "gallery";

	  if(this.state.editid==1){
			
		console.log(1)
		var loaderimg = rehabURL+'/' +this.state.image_preview;
		var preview = <img className="img_class" src={loaderimg} alt="" />;
	}else {
	
		
		console.log(2) 
		var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

	}

	let imageslist = [];

	let indexlength =  imageslist.length;
	
	const imgThumblist =  this.fileArray.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				))

				const galleryhub =  selectedimages.map((image,index) => (
					<li className="thumb" key={index}  >
						<span><img src={mediaUrl+image_path+'/'+image} alt={index}  width="100px" height="100px"/></span>
						<a href="javascript:void(0);" onClick={this.removeImage.bind(this,index)}>X</a>
					</li>
				))
    return (
      <div className="wrapper"> 
	  
<div className="confirm-action showconvey">
<p>You can't upload more than five images</p>
<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">OK</a>
</div>
 <div className="confirm-action">
			<p>Are you sure! you want to delete this image?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
	  <Header />
    <MenuSidebar currentpage="rehabs" />  	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/rehabs">Back</a>
			</div>
			<div className="title">
				<h4>Edit Details</h4>
				
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data" >
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label> Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				
				<div className="form-group">
					<label>Year Started</label>
				    
					<Select 
							value={selectedYear?selectedYear:''}
								options={lang.common.year_option} 
								onChange={this.handleYearChange.bind(this)}
								/>
					<div className="erroryear_of_started"></div>
				</div> 
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} >* </span> : </label>
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.description}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
							} }
							name="description"
							// onChange={this.handleInputChange}
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({description: data});
								
							} }
						/> 
					<div className="errordescription"></div>
				</div>
				{this.state.enableuploadgalley ==  true && <div className="cargallery-popup active">
					<span className="close-gallery" onClick={this.galleryClose.bind(this)}>X</span>
					<div className="cargallery-popup-inner">
						<Scrollbars className="custom-scroll-wrap">
						 <div className="car-listing">
								{this.getGalleryimages()}
						</div>
						</Scrollbars>
					</div>						

				<div className="btn-group">	
					  <span className="btn" onClick={this.galleryClose.bind(this)} disabled={(this.state.Loading ===true)?true:false}> 
					  {this.state.Loading ===true &&  <span className="load-data"></span>}OK</span>
				</div>
				
				</div>}
				{/* <div className="form-group">					
					<label>Description</label>
						
					<MyEditor initialData={this.state.description} onEditorChange={this.onEditorChange}> </MyEditor>
					<div className="errordescription"></div>
				</div> */}
			{/* <div className="form-group">
					<label>Image (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >*</span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					<div className="errorfile"></div>  
				</div> */}
               
			   <div className="form-group">					


			<label>Gallery Image</label>
			    <div className="choose-car" onClick={this.opengalleryImages.bind(this)}>
					<span>Upload from gallery</span>
					</div>
					</div>
					<ul className="carimagelisting">
								
								{galleryhub}
								</ul>
					<p className="or"><b>OR</b></p>
				<div className="form-group"> 
										<label>Image Gallery<span class="required" style={{ color: "red" }} > </span>(JPG,SVG,JPEG, PNG, GIF)</label>
											<div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											<span className="profile_btn">
											<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
											</div>
								 <ul className="carimagelisting">
								{/*imageslist*/}
								{imgThumblist}
								</ul>
								<div className="erroruser_image"></div>
								</div>
				   
				</div>
			<div className="form-right">
			<div className="form-group">
					<label>Rehab Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="rehabname" onChange={this.handleInputChange} className="form-control" value={this.state.rehabname} />
					<div className="errorrehabname"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} />
					<div className="erroremail"></div>
				</div>
				<div className="form-group">	
					<label>Amenities</label>

				
								
								<Multiselect
						displayValue="label"
						onRemove={this.handleProductCategoryChange}

						onSelect={this.handleProductCategoryChange}
						value={this.state.selectedamenityid}
						selectedValues={selectedcategoryvalue?amenitynamelist.filter(obj => selectedcategoryvalue.includes(obj.value)):{ }}
						options={this.state.amenitynamelist}
						/>
	
					
					<div className="errorproductcategory"></div>
				</div>
				<div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           </div> 
			   <div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>

				
				</div>
			</div>	
			<div className="title">
					<h4> Galleries</h4>
			</div>
			
			<div className="form-row">
				<div className="form-left">
	
				{galleries!=''?<div className="form-group">
				<div>
      <label>Galleries</label>
      <div>
       
		{galleries.map((item) => (
          <div key={item.rehab_gallery_id}>
            <img width="100px" height="100px" src={mediaUrl+item.image_path+'/'+item.image} alt={`Image ${item.rehab_gallery_id}`}  />
            
			<Link onClick={this.confirmPopup.bind(this, item.rehab_gallery_id,item.image_path,item.image)} className="" title="Delete">X</Link>
          </div>
        ))}
      </div>
    
    </div>
				</div>:''}
				
					
				</div>

			</div>	
			<div className="title">
					<h4> Address</h4>
			</div>

			<div className="form-row">
				<div className="form-left">
	
				<div className="form-group">
					<label>Street</label>
				    <input type="text" name="street" onChange={this.handleInputChange} className="form-control" value={this.state.street} />
					<div className="errorstreet"></div>
				</div>
				<div className="form-group">
						<label>State</label>
						<input type="text" name="state" onChange={this.handleInputChange} className="form-control" value={this.state.state} />
						<div className="errorstate"></div>
					</div>
				
				  
				<div className="form-group">
					<label>Postal Code</label>
				    <input type="text" name="postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.postalcode} />
					<div className="errorpostalcode"></div>
				</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City</label>
				    <input type="text" name="city" onChange={this.handleInputChange} className="form-control" value={this.state.city} />
					<div className="errorcity"></div>
				</div>
				<div className="form-group">
					<label>Country</label>
				    <input type="text" name="country" onChange={this.handleInputChange} className="form-control" value={this.state.country} />
					<div className="errorcountry"></div>
				</div> 
					
				</div>

			</div>
			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Edit);