import React, { Component } from 'react';
import { Link,useParams, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,rehabURL,mediaUrl} from'../Config/Config';
import axios from 'axios';
import MyEditor from '../Membership/MyEditor';
import { Scrollbars } from 'react-custom-scrollbars';

/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Edit extends Component {
	
	
	constructor(props)
	{
		super(props);	
	    const driverid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
			driverid: driverid,
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			status:'',
			
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			domainlistlength:'',
			maxCharacterLimit : 20,
			
			street:'',
			city:'',
			state:'',
			country:'',
			postalcode:'',
			rehablists:'',
		
			selectedRehabvalue:'',
		};

		
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	
	   this.handleChange = this.handleChange.bind(this);

    }

	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		
	this.listloaddata()
    }
	listloaddata=()=>{
		
		var qs = require('qs');
			var admin_id = localStorage.getItem('admin_id');

			var postobject = {
				admin_id: admin_id
			};
		let rehablists = [];
			axios.post(apiUrl+"rehab/rehablist",qs.stringify(postobject)).then(res => {
				if(res.data.status == "success"){
					let i=0;
					let rehablist = res.data.rehablist;
					
					for(i; i<rehablist.length; i++){
						rehablists[i] = { value: rehablist[i]['rehab_id'], label: rehablist[i]['center_name'] };
					}
					this.setState({
						rehablists:rehablists
					});
				
				}       
			});
		var qs = require('qs');
		axios.get(apiUrl+"driver/listdetail?id="+this.state.driverid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.driverlist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			// console.log(res.data.galleries,"res.data.galleriesres.data.galleries")
			           						
			            this.setState({firstname:formdata.firstname});						
			            this.setState({lastname:formdata.lastname});						
			            this.setState({rehab_id:formdata.rehab_id});						
						this.setState({email:formdata.email});
						this.setState({phoneno:formdata.mobile});
						this.setState({street:formdata.street});
						this.setState({city:formdata.city});
						this.setState({state:formdata.state});
						this.setState({country:formdata.country});
						this.setState({postalcode:formdata.pincode});
						this.setState({selectedRehabvalue:formdata.rehab_id});

						var qs = require('qs');

					
						var postObject = {
							id: res.data.driverlist.domain_id,			
						};
						
						axios.post(apiUrl+"user/domainlist",qs.stringify(postObject)).then(res => {
							if(res.data.status == "success"){					
								let domaindata = res.data.domainlist;
								this.setState({selectedDomainOption:{value: domaindata[0]['domain_id'], label: domaindata[0]['domain_name']}});					
								this.setState({selectedDomainvalue:domaindata[0]['domain_id']});					
							}
						});
			}
			
		}); 
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'rehab/getamenitynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						amenitynamelist:res.data.amenitynamelist
					});
				
				}else{
				}
			});

	}
	
	isUSZipCode(zipCode) {
		// Regular expression pattern for validating US ZIP codes
		const zipCodePattern = /^\d{5}(-\d{4})?$/;
		return /^\d{5}(-\d{4})?$/.test(zipCode) || /^\d{9}$/.test(zipCode);
	}
   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
    }
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	

	
	
 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
				var selectedamenitys='';
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				

				var postObject = {
			      
					admin_id : localStorage.getItem("admin_id"),
					driverid       : formPayload.driverid,
					domain_id       : formPayload.selectedDomainvalue,
					firstname    : formPayload.firstname,
					lastname    : formPayload.lastname,
					rehab_id     : formPayload.selectedRehabvalue,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					street      : formPayload.street,
					city      : formPayload.city,
					state      : formPayload.state,
					country      : formPayload.country,
					postalcode      : formPayload.postalcode,
					description      : formPayload.description,
					status       :  status,
						

				};
				axios.post(apiUrl+"driver/edit",qs.stringify(postObject)).then(res => {

					
					if(res.data.status === "success"){
						this.setState({ Loading: false });

						const formpayload = res.data.driverlist;
						
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									//this.props.navigate('/user');
								}
								.bind(this),
								3000
								);
					} else {
						
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {
		const {selectedRehabvalue,firstname,lastname, email, phoneno,selectedDomainvalue,postalcode} = this.state;

		let errors = 0;
		const nameRegex = /^[A-Za-z\s.]+$/;
		if (!selectedRehabvalue) {
			errors++;
			$('.errorrehab').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedRehabvalue){
			
				$('.errorrehab').html('');
			}
			
		
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}
		
		if (!selectedDomainvalue) {
		 	errors++;
		 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(selectedDomainvalue){
		 	$('.errorassign_domain').html('');
		 }
		
		
		if (!lastname) {
			errors++;
			$('.errorlastname').html('<span class="errorspan">Please fill the field</span>');
		}else{
				$('.errorlastname').html('');
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

		// if(postalcode){
		// 	if(this.isUSZipCode(postalcode)){
		// 		$('.errorpostalcode').html('');
		// 	}else{
		// 		errors++;
		// 		$('.errorpostalcode').html('Please enter valid postal code');
		// 	}
		// }
		
		  
			

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    
	  handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}

handleYearChange = selOption => {
	this.setState({selectedYear:selOption})
		this.setState({  selectedYearvalue : selOption.value});
};
handleRehabChange = selectedRehabOptions => {
		
	this.setState({selectedRehabOptions:selectedRehabOptions})
	this.setState({  selectedRehabvalue : selectedRehabOptions.value});

}
render() {
  	
	const {
		selectedRehabvalue,
		selectedOption,
		selectedDomainOption,
		selectedimages,
		rehablists,
		selectedRehabOption,
	  } = this.state;
	  
	  console.log(rehablists, "rehablists");
	  console.log(this.state.selectedRehabvalue, "this.state.selectedRehabvalue");
	  
	  let selectedRehabOptions; // Declare the variable outside the if block
	  
	  if (rehablists !== "") {
		selectedRehabOptions = rehablists.find(option => option.value === this.state.selectedRehabvalue);
	  }
	  

  return (
	<div className="wrapper"> 


	<Header />
  <MenuSidebar currentpage="drivers" />  

  
  <div className="content">	
	  <div className="content-wrapper">
	  <div class="content-wrapper-before"></div>
		  <div className="form-wrapper leaa-admin-head card">

		  <div className="success_message"></div>
		  <div>
				<a class="primary-btn" href="/drivers">Back</a>
			</div>
		  <div className="title">
			  <h4>Edit Driver</h4>
		  </div>
	  <form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
		  <div className="form-row">
		  <div className="form-left">
		  <div className="form-group">
				  <label>Rehab</label>
				  
				  <Select 
						  value={selectedRehabOptions?selectedRehabOptions:''}
							  options={rehablists} 
							  onChange={this.handleRehabChange.bind(this)}
							  />
				  <div className="errorrehab"></div>
			  </div>
			  
			  <div className="form-group">
				  <label>Last Name<span class="required" style={{ color: "red" }} >*</span></label>
				  <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
				  <div className="errorlastname"></div>
			  </div>
			  <div className="form-group">
				  <label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				  <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
				  <div className="errorphoneno"></div>
			  </div>
			  
			   
			  
			 <div className="default-font">
			  <div className="form-group">					
				  <label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						 <Select 
						 value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
						  options={lang.common.status_option} 
						  onChange={this.handleChange}
						  />
			  </div>
			  </div>
			  
		  
			  </div>
		  <div className="form-right">
		  
			  
			  <div className="form-group">
				  <label>First Name<span class="required" style={{ color: "red" }} >*</span></label>
				  <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
				  <div className="errorfirstname"></div>
			  </div>
			  <div className="form-group">
				  <label>Email<span class="required" style={{ color: "red" }} >*</span></label>
					  <input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
				  <div className="erroremail"></div>
			  </div>

			  
			  <div className="form-group">					
				  <label>Assign Domain<span class="required" style={{ color: "red" }} >* </span></label>
						 <Select 
						 value={selectedDomainOption?selectedDomainOption:""}
						  options={lang.common.domainList_option} 
						  onChange={this.handledomainChange}
						  />
						  <div className="errorassign_domain"></div>
			 </div> 
			  
			  
			  
				  
			   
			  </div>
		  </div>
		  <div className="title">
				  <h4> Address</h4>
		  </div>

		  <div className="form-row">
			  <div className="form-left">
  
			  <div className="form-group">
				  <label>Street</label>
				  <input type="text" name="street" onChange={this.handleInputChange} className="form-control" value={this.state.street} />
				  <div className="errorstreet"></div>
			  </div>
			  <div className="form-group">
					  <label>State</label>
					  <input type="text" name="state" onChange={this.handleInputChange} className="form-control" value={this.state.state} />
					  <div className="errorstate"></div>
				  </div>
			  
				
			  <div className="form-group">
				  <label>Postal Code</label>
				  <input type="text" name="postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.postalcode} />
				  <div className="errorpostalcode"></div>
			  </div>             
			  </div>


			  <div className="form-right">
			  <div className="form-group">
				  <label>City</label>
				  <input type="text" name="city" onChange={this.handleInputChange} className="form-control" value={this.state.city} />
				  <div className="errorcity"></div>
			  </div>
			  <div className="form-group">
				  <label>Country</label>
				  <input type="text" name="country" onChange={this.handleInputChange} className="form-control" value={this.state.country} />
				  <div className="errorcountry"></div>
			  </div> 
				  
			  </div>

		  </div>
		  <div className="btn-group export">	
				 <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
									  <span className="load-data"></span> 
									  }Submit
				 </button>
		  </div>
		  </form>
		  </div>
			  
	  </div>	
  </div>
		   
  </div>
  );
}
}


export default withRouter(Edit);