import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Select from 'react-select';
import moment from 'moment';
/* import { GET_VIEWappointmentlist } from '../../actions'; */
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const appointmentid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      appointmentid: appointmentid,
      appointmentlistview:'',
      selectedrehabOption:'',
			selectedrehabvalue:'',
      rehablist:'',
      tracklist:'',
      trackAppt:'',
      rehabliststate:'',
      apptTrackSt:'',
      cancelstatus:'',
      appintmentcommitted_id:'',
     
    };

    
  }

   
   componentDidMount() {
     document.title = PageTitle('User View');
     var qs = require('qs');
     var postObject = {
   id : this.state.appointmentid,
      list: 'New'
 };
 axios.post(apiUrl+"appointment/listdetail",qs.stringify(postObject)).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.appointmentlist;
			if(formdata.app_status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({appointmentlistview:res.data.appointmentlist,trackAppt:res.data.trackAppt},
        function(){        
        this.setState({apptTrackSt:this.trackAppt()})
      });
						
			}
			
		}); 
     var qs = require('qs');
		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
		}
		let rehablists = [];
		axios.post(apiUrl+"rehab/rehablist",qs.stringify(postObject)).then(res => {
			if(res.data.status == "success"){
				let i=0;
				let rehablist = res.data.rehablist;
				
				for(i; i<rehablist.length; i++){
					rehablists[i] = { value: rehablist[i]['rehab_id'], label: rehablist[i]['center_name'] };
				}
				this.setState({rehablist:rehablists})
			}       
		});
   }

   handlerehabChange = selectedrehabOption => {
    this.setState({selectedrehabOption:selectedrehabOption})
    this.setState({  selectedrehabvalue : selectedrehabOption.value});
};
trackAppt() {
  var trackAppt = this.state.trackAppt;
  if (trackAppt != "undefined" && trackAppt != null) {
    //if ( == "success"){
      if (Object.keys(trackAppt).length > 0) {
        const trackApptDetails = trackAppt.map(
          (trackAppt, Index) => {
            let sno = Index+1;
            var track_activity='';            
            return (

            
              <tr key={Index}>
               <td>{sno}</td>
              
                 <td>
                
                {/* {moment(tracklist.created_date).format('DD MMM YYYY')} */}
                {trackAppt.auto_gen_comment}
                </td>
                <td> 
                  {moment(trackAppt.updated_on).format('DD MMM YYYY, h:mm A')}
                  </td>
                <td>
                {trackAppt.feedback!=null && trackAppt.feedback !=''?trackAppt.feedback:'-'}
                </td>
               
               
               
              </tr>
            );
          }
        );
        return trackApptDetails;
      }
    
  }
}
   
  render() {
    
  	const {rehablist,selectedrehabOption} = this.state;

    var medical_details = ((this.state.appointmentlistview.medical_details === null)||((this.state.appointmentlistview.medical_details === ''))) ?  this.state.appointmentlistview.medical_details = 'N/A' : this.state.appointmentlistview.medical_details;

    
    if(this.state.appointmentlistview.app_status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    if(this.state.appointmentlistview.gender == 'M'){
      var gender = 'Male';
    }else if(this.state.appointmentlistview.gender == 'F'){
      var gender = 'Female';
    }else{
      var gender = 'Transgender';
    }

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="appointment-observation" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                {/* <div className="title">
                  <h3>Appointment Details</h3>
                </div> */}
                <div className="listing-header"> 
                  <div className="title">
                    <h3>Appointment Details</h3>
                  </div>
                    
                  <div className="add_button">  
                  <a className="" href='/appointment-observation'><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>              
                  </div>
                </div>
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Appt ID<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.appointment_code}</p>
                          </div>
                        </div>
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Patient Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.patient_name}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Gender<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{gender}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Age<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.age}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                            <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.appointmentlistview.user_email!=null?this.state.appointmentlistview.user_email:'N/A'}</p>
                          </div>
                      </div>
                      <div className="view-details">
                          <div className="view-details-one">
                            <p><b>Mobile<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.appointmentlistview.user_mobile!=null?this.state.appointmentlistview.user_mobile:'N/A'}</p>

                          </div>
                      </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Purpose of Appt<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.purpose_to_book!=''?this.state.appointmentlistview.purpose_to_book:'N/A'}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Scheduled On<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{(this.state.appointmentlistview.schedule!='' && this.state.appointmentlistview.schedule!='0000-00-00')?moment(this.state.appointmentlistview.schedulemoment).format("MM/DD/YYYY, h:mm A"):'N/A'}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Pickup location<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.appointmentlistview.pickup_location!=''?this.state.appointmentlistview.pickup_location:'N/A'}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Medical Details<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{medical_details}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Requested Rehab<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.center_name!=null?this.state.appointmentlistview.center_name:'N/A'}</p>
                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Committed  Rehab<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.committed_rehab!=null?this.state.appointmentlistview.committed_rehab:'N/A'}</p>
                          </div>
                        </div> */}
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.domain_name}</p>
                          </div>
                        </div>

                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{status}</p>
                          </div>
                        </div> */}


                    </div>
                </div>
                 
                  
                {/* <div className="title">
                  <h3>Address Details</h3>
                </div>
                <div className="form-row">
                <div className="details_left">
                <div className="view-details">
                          <div className="view-details-one">
                              <p><b> Street Address<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.street!=null?this.state.appointmentlistview.street:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>City<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                       
                          <p>{this.state.appointmentlistview.city!=null?this.state.appointmentlistview.city:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>State<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.state!=null?this.state.appointmentlistview.state:'N/A'}</p>

                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Country<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.appointmentlistview.country!=null?this.state.appointmentlistview.country:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Zip Code<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                        
                          <p>{this.state.appointmentlistview.pincode!=null?this.state.appointmentlistview.pincode:'N/A'}</p>

                          </div>
                        </div>
                        </div>
                        </div> */}
                         {this.state.appointmentlistview.committed_id!="" && this.state.appointmentlistview.committed_id!=0 ?
                        
                        <div className="title">
                          <h3>Rehab Details</h3>
                        </div>
                        :''}
                        {this.state.appointmentlistview.committed_id!="" && this.state.appointmentlistview.committed_id!=0 ?
                        <div className="form-row">
                          <div className="details_left">
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Committed Rehab<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.committed_rehab!=null?this.state.appointmentlistview.committed_rehab:'N/A'}</p>
                                </div>
                            </div>
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Email<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.rehab_email!=null?this.state.appointmentlistview.rehab_email:'N/A'}</p>
                                </div>
                            </div>
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Mobile<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.rehab_mobile!=null?this.state.appointmentlistview.rehab_mobile:'N/A'}</p>
      
                                </div>
                            </div>                                                    
                          </div>
                        </div>
                        
                      : ''}

{this.state.appointmentlistview.driv_commits_id!="0" ?
                        
                        <div className="title">
                          <h3>Driver Details</h3>
                        </div>
                        :''}
                        {this.state.appointmentlistview.driv_commits_id!="0" ?
                        <div className="form-row">
                          <div className="details_left">
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b> Name<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.driver_name!=null?this.state.appointmentlistview.driver_name:'N/A'}</p>
                                </div>
                            </div>
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Email<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.driver_email!=null?this.state.appointmentlistview.driver_email:'N/A'}</p>
                                </div>
                            </div>
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Mobile<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.driver_mobile!=null?this.state.appointmentlistview.driver_mobile:'N/A'}</p>

                                </div>
                            </div> 
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Booked On <span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{(this.state.appointmentlistview.driver_pickup_datetime!=null && this.state.appointmentlistview.driver_pickup_datetime!="0000-00-00 00:00:00")?moment(this.state.appointmentlistview.driver_pickup_datetime).format("MM/DD/YYYY, h:mm A"):'N/A'}</p>

                                </div>
                            </div>                                
                            <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Dropped On <span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{(this.state.appointmentlistview.driver_dropped_time!='' && this.state.appointmentlistview.driver_dropped_time!='0000-00-00 00:00:00')?moment(this.state.appointmentlistview.driver_dropped_time).format("MM/DD/YYYY, h:mm A"):'N/A'}</p>

                                </div>
                            </div>                                
                            {/* <div className="view-details">
                                <div className="view-details-one">
                                  <p><b>Message<span>:</span> </b></p>
                                </div>
                                <div className="view-details-two">
                                  <p>{this.state.appointmentlistview.rehabDriver_message!=null?this.state.appointmentlistview.rehabDriver_message:'N/A'}</p>

                                </div>
                            </div>                                 */}
                          </div>
                        </div>
                        
                         : ''}
                         <div className="title">
                    <h3>Appointment Tracking</h3>
                  </div>
                  <div className="form-row">
                    <div className="car-listing-row">
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Status</th>
                              <th>Date</th>                             
                              <th> Feedback</th>
                              
                            </tr>
                          </thead>
                          <tbody>{this.state.apptTrackSt}</tbody>
                        </Table>
                      
                      </div>
                  </div>
                
                     
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
