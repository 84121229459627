import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, mediaUrl,carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import MyEditor from '../Membership/MyEditor';
import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Scrollbars } from 'react-custom-scrollbars';

const initialState = {
			username:'',
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			status:'',
			user_password: '',
            user_confirm_password: '',
            new_pass_type: 'password',
            confrim_pass_type: 'password',
            pass_show_hide_icon: 'shown',
            confirm_show_hide_icon: 'shown',
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			disablepasssword:true,
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			domainlistlength:'',
			maxCharacterLimit : 20,
			selectedYear:'',
			selectedYearvalue:'',
			street:'',
			city:'',
			state:'',
			country:'',
			postalcode:'',
			amenitynamelist:[],
			selectedamenityid:'',
			image_preview:'',
			image_name:'',
			image:'',
			fileExtension: '',	
			user_image: '',	
			enableuploadgalley: false,
			imagelist:'',
			selectedimages:[],
			galleryimg: 'No',
		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {
	fileObj = [];
    fileArray = [];
    imageArray = [];
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
       this.handleChange = this.handleChange.bind(this);
	   this.removeImagebyindex = this.removeImagebyindex.bind(this);
	   this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
	   this.onEditorChange = this.onEditorChange.bind( this );
	   this.opengalleryImages = this.opengalleryImages.bind( this );

    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name == 'password'){
      	this.setState({disablepasssword: false})
      }
    }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleYearChange = selOption => {
        this.setState({selectedYear:selOption})
			this.setState({  selectedYearvalue : selOption.value});
    };
	handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}
uploadMultipleFiles = (e) =>{
	this.fileObj = [];

        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i]
            this.imageArray.push(this.fileObj[0][i])

        }
		this.setState({ user_image: this.imageArray,galleryimg:2,selectedimages:[] })		
    }


     removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		
		
	}
	
onChangeHandler=event=>{
	let reader = new FileReader();
	const file = event.target.files[0];
	const extension = file.name.split('.').pop();
	reader.onloadend = () => {
	  this.setState({
		image_name: file.name,
		image_preview: reader.result,
		image: file,
		fileExtension:extension,
		
	  });
	};
	reader.readAsDataURL(file);

	
  }
//  isUSZipCode(zipCode) {
// 	// Regular expression pattern for validating US ZIP codes
// 	const zipCodePattern = /^\d{5}(-\d{4})?$/;
// 	return zipCodePattern.test(zipCode);
//   }
   isUSZipCode(zipCode) {
    // Regular expression pattern for validating US ZIP codes
    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    return /^\d{5}(-\d{4})?$/.test(zipCode) || /^\d{9}$/.test(zipCode);
}
	componentDidMount() {
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add rehab');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		let domainlist = [];
		axios.post(apiUrl+"user/domainlist").then(res => {
			if(res.data.status == "success"){
				let i=0;
				let domainlists = res.data.domainlist;
		
				for(i; i<domainlists.length; i++){
					
					domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
				}
				
				if(res.data.domainlist.length==1){
				let	j=0;
					for(j; j<res.data.domainlist.length; j++){
						
					 selecteddomainOption = { value:domainlists[j]['domain_id'], label: domainlists[j]['domain_name'] };
				    selectedDomainvalue = domainlists[j]['domain_id'];	
				}
					
					this.setState({selectedDomainOption:selecteddomainOption})
					this.setState({selectedDomainvalue:selectedDomainvalue})
				}

			}       
		});
		
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'rehab/getamenitynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						amenitynamelist:res.data.amenitynamelist
					});
				
				}else{
				}
			});
			
		

    }
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    } 

	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				const selectedamenityid = formPayload.selectedamenityid;
				var elements=[];
				let catList  = ''
				if(selectedamenityid!=='' && typeof selectedamenityid!==undefined && selectedamenityid!==null) {
					catList = selectedamenityid.map((number) =>
					elements.push(number.value)
				);
				}
	                
	             const selectedcategorys=elements.toString();
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id       : formPayload.selectedDomainvalue,
					name    : formPayload.firstname,
					startedYear    : formPayload.selectedYearvalue,
					rehabname     : formPayload.rehabname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					street      : formPayload.street,
					city      : formPayload.city,
					state      : formPayload.state,
					country      : formPayload.country,
					postalcode      : formPayload.postalcode,
					amenityids       :  selectedcategorys,
					image : formPayload.image,
					description : formPayload.description,

					status       :  status,
				};
				          let formData = new FormData();
							for(let k in postObject) {
								formData.append(k, postObject[k]);
							}
				              var filesdata = this.state.user_image;
				              var galleryimg = this.state.galleryimg;
							  if(galleryimg==2){
								for (var i in filesdata) {
									formData.append('multiple_images[]',filesdata[i])
								}
							}
							var selectedimages;
				
							selectedimages = this.state.selectedimages;
					if(galleryimg==1){

						for (var i in selectedimages) {
							formData.append('images[]',selectedimages[i])
						}	
					}
								
						
					
					
								
				axios.post(apiUrl+"rehab/add",formData).then(res => {
				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							username:'',			
							firstname:'',
							lastname:'',
							email:'',
							phoneno:'',
							password: '',
							confirm_password:''
						   });
						   setTimeout(
						   function() {
					// 		var galleryimg = 'No';
					// if(Object.keys(formPayload.selectedimages).length >0){
	                //    galleryimg = 'Yes';
					// }
							   $('.success_message').html('');
									this.props.navigate('/rehabs');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {firstname,galleryimg, selectedimages,rehabname,email, phoneno,selectedDomainvalue,postalcode,image,description,user_image} = this.state;
		const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
		let maxSize = 1000000;//1 mb

		let errors = 0;
		const nameRegex = /^[A-Za-z\s.]+$/;
       var galleryhubCount =  (selectedimages)?selectedimages.length:0;
       var selectedlocalCount =  (user_image && user_image.length)?user_image.length:0;
		if (!description) {
			errors++;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if (!selectedDomainvalue) {
		 	errors++;
		 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(selectedDomainvalue){
		 	$('.errorassign_domain').html('');
		 }
		
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}
		if (!rehabname) {
			errors++;
			$('.errorrehabname').html('<span class="errorspan">Please fill the field</span>');
		}else{
				$('.errorrehabname').html('');
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

		if(postalcode){
			if(this.isUSZipCode(postalcode)){
				$('.errorpostalcode').html('');
			}else{
				errors++;
				$('.errorpostalcode').html('Please enter valid postal code');
			}
		}
		if (user_image) {
			 
			
	             for (let i = 0; i < user_image.length; i++) 
				 {

					const file = user_image[i];
					const filename = file.name;

					// if (file.size > maxSize) {
					// 	errors++;
					
					// $('.erroruser_image').html('<span class="errorspan">'+filename+'File is too large. Max size is 1MB</span>');
					// continue;
					// }
			
					if (!allowedTypes.includes(file.type)) {
						errors++;
					
					$('.erroruser_image').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

					continue;
					}
	            }
	       }
		  
				if(galleryimg==1&&galleryhubCount >= 5){
					
					errors++;
					$('.erroruser_image').html('<span class="errorspan">You can only upload up to 5 images</span>');
					//fileErrors.push('You can only upload up to 10 images.');
				
			}else{
				$('.erroruser_image').html('<span class="errorspan"></span>');
			}
			if(galleryimg==2&&selectedlocalCount >= 5){
					
				errors++;
				$('.erroruser_image').html('<span class="errorspan">You can only upload up to 5 images</span>');
				//fileErrors.push('You can only upload up to 10 images.');
			
		}else{
			$('.erroruser_image').html('<span class="errorspan"></span>');
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    componentWillReceiveProps(Props){

    		if(Props.useradd !== this.props.useradd){
    		if(Object.keys(Props.useradd).length > 0){
				this.setState({Loading:false});

				if(Props.useradd[0].status === "success"){

				 $('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
				scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html(this.props.history.push('/rehabs'));
						}
						.bind(this),
						3000
						);
					this.setState({username:'',firstname:'',lastname:'',email:'',phoneno:'',user_password:'',user_confirm_password:''})
					}else if(Props.useradd[0].status === "erroremail"){

					 $('.erroremail').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.erroremail').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorphone"){

					 $('.errorphoneno').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorphoneno').html('');
							}
							.bind(this),
							3000
							);
					}else if(Props.useradd[0].status === "errorusername"){

					 $('.errorusername').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
				     	scrollToTop();
							setTimeout(
							function() {
								$('.errorusername').html('');
							}
							.bind(this),
							3000
							);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
						scrollToTop();
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
    }
	handleClickSelecet = (event) => {
		const selectedIndex = event.target.options.selectedIndex;
		var key = event.target.options[selectedIndex].getAttribute('data-key');
		this.setState({
				domain_id: key,
				
		})


	}
	handleProductCategoryChange = (event) => {
		this.setState({selectedamenityid : event}, function() {
		   const user_list = this.state.selectedamenityid;
		 
		});
	}
	getGalleryimages() {
		var imageArr = this.state.imagelist;
		if(imageArr!== undefined && imageArr!== null){
		  if(Object.keys(imageArr).length > 0) {
			 const imageDetails = imageArr.map((image, Index) => {
				   var checked = false;
				return (
							<div className="asp-col" key={Index}>   
							<input type="checkbox" name="gallery_img" value={Index}  onClick={this.Checkgallery.bind(this,Index,image['name'])} />
							<label><img src={image['url']} alt="" className="" /></label>
							</div>
						 );
		
		 });
		  return imageDetails;
		 }
		} else {
		  return (<div className="">No images found</div>);
		}
	  }
	onEditorChange( evt ) {
		
		console.log(evt, 'CKE')
	   // this.setState({description: evt
	   // } );
	   var callfunc = this;
	   setTimeout(function () {
	   this.setState( {
		   description: evt
	   } );
	   }.bind(this),1000);
	
   }
   opengalleryImages = () => {
	axios.get(apiUrl+'rehab/getimagelist')
	.then(res => {
		if(res.data.status == 'success'){
			this.setState({imagelist: res.data.imagelist})
		}else{
		   
		}
	});
	this.setState(prevState => ({
		enableuploadgalley: !prevState.enableuploadgalley
	}));
}
galleryClose = () => {

	if(Object.keys(this.state.selectedimages).length > 0){
		
	}
	this.setState(prevState => ({
	 enableuploadgalley: !prevState.enableuploadgalley
 }));
 $('.cargallery-popup').removeClass('active');
}
Checkgallery = (indexs,value) =>{
	var imageArr = [...this.state.selectedimages];
		var imgcount =	imageArr!=''?imageArr.length:0;
	const index = imageArr.findIndex(images => images === indexs);
	if(imgcount>=5){
		$('.showconvey').addClass('show');	
	}else{
		if(index > -1) {
			imageArr = [...imageArr.slice(0, index), ...imageArr.slice(index + 1)]
		} else {
		imageArr.push(value);
		}
		this.setState({selectedimages: imageArr,galleryimg:1,user_image:[],fileArray:[]});
	}
	

}
Confirmstatus = (confirmstatus) => {
	if(confirmstatus == 'no'){
	  $('.showconvey').removeClass('show');
	  $('.cargallery-popup').removeClass('active');

	}else{
		setTimeout(
			function() {
		   
				$('.showconvey').removeClass('show');
				$('.cargallery-popup').removeClass('active');

			}
			.bind(this),
			3000
			);
	}
	  
  }
  removeImage(index) {
	const updatedImages = [...this.state.selectedimages];
	updatedImages.splice(index, 1);
	this.setState({ selectedimages: updatedImages });
  }
  render() {
  	
  	const {selectedYear,selectedOption,selectedDomainOption,selectedimages} = this.state;
	  var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;
	  let imageslist = [];
	  let popupimageslist = [];

	  let indexlength =  imageslist.length;
	  let image_path =  "gallery";
	  
	  const gallerylocal =  this.fileArray.map((url,index) => (
					  <li className="thumb" key={indexlength+index}  >
						  <span><img src={url}  alt="..." /></span>
						  <a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					  </li>
				  ))

				  const galleryhub =  selectedimages.map((image,index) => (
					<li className="thumb" key={index}  >
						<span><img src={mediaUrl+image_path+'/'+image} alt={index}  width="100px" height="100px"/></span>
						<a href="javascript:void(0);" onClick={this.removeImage.bind(this,index)}>X</a>
					</li>
				))
				
    return (
      <div className="wrapper"> 

<div className="confirm-action showconvey">
<p>You can't upload more than five images</p>
<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">OK</a>
</div>
	  <Header />
    <MenuSidebar currentpage="rehabs" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/rehabs">Back</a>
			</div>
			<div className="title">
				<h4>Add Rehab</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			
				<div className="form-group">
					<label>Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				
				<div className="form-group">
					<label>Year Started</label>
				    
					<Select 
							value={selectedYear?selectedYear:''}
								options={lang.common.year_option} 
								onChange={this.handleYearChange.bind(this)}
								/>
					<div className="erroryear_of_started"></div>
				</div> 
				<div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           </div> 
			   {this.state.enableuploadgalley ==  true && <div className="cargallery-popup active">
					<span className="close-gallery" onClick={this.galleryClose.bind(this)}>X</span>
					<div className="cargallery-popup-inner">
						<Scrollbars className="custom-scroll-wrap">
						 <div className="car-listing">
								{this.getGalleryimages()}
						</div>
						</Scrollbars>
					</div>						

				<div className="btn-group">	
					  <span className="btn" onClick={this.galleryClose.bind(this)} disabled={(this.state.Loading ===true)?true:false}> 
					  {this.state.Loading ===true &&  <span className="load-data"></span>}OK</span>
				</div>
				
				</div>}
				<div className="form-group">
			    <label> Upload Gallery </label>

			    <div className="choose-car" onClick={this.opengalleryImages.bind(this)}>
						 <span>Upload from gallery</span>
				</div>

				{/* {selectedimages.length>1?<div>
      {selectedimages.map((image, index) => (
		
        <img key={index} src={mediaUrl+image_path+'/'+image} alt={index} width="100px" height="100px"/>
		<button onClick={() => this.removeImage(index)}>Remove</button>
      ))}
    </div>:''} */}
	{/* {selectedimages.length>1?<div>
	{selectedimages.map((image, index) => (
        <div key={index}>
			<button onClick={() => this.removeImage(index)}width="10px" height="10px">X</button>
          <img src={mediaUrl+image_path+'/'+image} alt={index}  width="100px" height="100px"/>
          
        </div>
      ))}
    </div>:''} */}
	 <ul className="carimagelisting">
								
								{galleryhub}
								</ul>
				<p className="or"><b>OR</b></p>

				{/* <div className="choose-file">
                {this.state.isHidden && <div className="image_success"></div>}
                   <span className="profile_btn"><input type="file" name="file[]" multiple onChange={this.uploadMultipleFiles} /></span>
                </div>
                <div className="errorimage"></div> */}
                </div>
			   <div className="form-group"> 
										<label>Upload Gallery<span class="required" style={{ color: "red" }} > </span>(JPG,SVG,JPEG, PNG, GIF)</label>
											<div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											<span className="profile_btn">
											<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
											</div>
								 <ul className="carimagelisting">
								{/*imageslist*/}
								{gallerylocal}
								</ul>
								<div className="erroruser_image"></div>
								</div>
				{/* <div className="form-group">
					<label>Profile Image (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >* </span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					<div className="errorfile"></div>  
				</div>  */}
			
				</div>
			<div className="form-right">
			
				<div className="form-group">
					<label>Rehab Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="rehabname" onChange={this.handleInputChange} className="form-control" value={this.state.rehabname} />
					<div className="errorrehabname"></div>
				</div>
				
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>

				<div className="form-group">	
					<label>Amenities </label>
					<Multiselect
					displayValue="label"
					onKeyPressFn={function noRefCheck(){}}
					onRemove={this.handleProductCategoryChange}
					onSearch={function noRefCheck(){}}
					onSelect={this.handleProductCategoryChange}
					value={this.state.selectedcategoryid}
					options={this.state.amenitynamelist}
					/>
	
					<div className="errorproductcategory"></div>
				 </div>
				
				 <div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} >* </span> : </label>
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
							editor={ ClassicEditor }
							data={this.state.description}
							onReady={ editor => {
								// You can store the "editor" and use when it is needed.
								console.log( 'Editor is ready to use!', editor );
							} }
							name="description"
							// onChange={this.handleInputChange}
							onChange={ ( event, editor ) => {
								const data = editor.getData();
								this.setState({description: data});
								
							} }
						/> 
					<div className="errordescription"></div>
				</div> 
				{/* <div className="form-group">					
					<label>Description</label>
						
						<MyEditor  onEditorChange={this.onEditorChange}></MyEditor>
					
					<div className="errordescription"></div>
				</div>    */}
				<div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
					
				 
				</div>
			</div>
			<div className="title">
					<h4> Address</h4>
			</div>

			<div className="form-row">
				<div className="form-left">
	
				<div className="form-group">
					<label>Street</label>
				    <input type="text" name="street" onChange={this.handleInputChange} className="form-control" value={this.state.street} />
					<div className="errorstreet"></div>
				</div>
				<div className="form-group">
						<label>State</label>
						<input type="text" name="state" onChange={this.handleInputChange} className="form-control" value={this.state.state} />
						<div className="errorstate"></div>
					</div>
				
				  
				<div className="form-group">
					<label>Postal Code</label>
				    <input type="text" name="postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.postalcode} />
					<div className="errorpostalcode"></div>
				</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City</label>
				    <input type="text" name="city" onChange={this.handleInputChange} className="form-control" value={this.state.city} />
					<div className="errorcity"></div>
				</div>
				<div className="form-group">
					<label>Country</label>
				    <input type="text" name="country" onChange={this.handleInputChange} className="form-control" value={this.state.country} />
					<div className="errorcountry"></div>
				</div> 
					
				</div>

			</div>
			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);