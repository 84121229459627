import React, { Component } from 'react';
import { useParams, useNavigate, Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import moment from 'moment';

import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };

class ViewApptsList extends Component {
  
     
    constructor(props)
  {
     super(props);
     const driverid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
     const driver = (typeof props.params.DRIVER === 'undefined') ? '' : props.params.DRIVER;
      this.state = {
          appointmentlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
          selectedStatus:'',
          selectid:'',
          renderfunction:'',          
          setmessage:'',
          driverid: driverid,
          driver: driver,
      };
    

      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);

    }

   
   componentDidMount() {
   this.listloadedData();
   }
   listloadedData(postobject=''){ 
    var qs = require('qs');
    if(postobject!=''){
      var postobject = postobject
    }else{
     
      var postobject = {
      activePage: 1,
      search_all: '',
     // search_all: '',
      //listtype: 'new',
      admin_id: localStorage.getItem('admin_id'),
      driver_id : this.state.driverid
      };
    }
 
   axios.post(apiUrl+"Driver/appt_list",qs.stringify(postobject)).then(res => {
     
     if(res.data.status === 'success'){
         this.setState({  appointmentlist: res.data,  totalRecord: res.data.records_count}, function() {
          this.setState({ renderfunction:this.appointmentlist()})
         });
     }else{
      this.setState({  appointmentlist: '', totalRecord: 0}, function() {
        this.setState({ renderfunction:this.appointmentlist()})
      });
     }
   }); 
}

  handlePageChange(pageNumber) {
   
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id'),
      driver_id : this.state.driverid  
    };
    this.listloadedData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target; 
  var postObject = {             
    activePage   : 1,
    search_all : value,
    admin_id: localStorage.getItem('admin_id'),
    driver_id : this.state.driverid           
  };     
  this.setState({
    [name]: value
  },function(){
    this.listloadedData(postObject)
  });
  
}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : 1,
          search_all : formPayload.search_all,
          admin_id: localStorage.getItem('admin_id'),
          driver_id : this.state.driverid           
        };
        this.listloadedData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

// deleteuser(id){
//         var qs = require('qs');
//         var postObject = {
//           admin_id : localStorage.getItem("admin_id"),
//           appointment_id  :id
//         };
//         axios.post(apiUrl+"appointment/list",qs.stringify(postObject)).then(res => {      
//           if(res.data.status === 'success'){
//               this.setState({  appointmentlist: res.data, totalRecord: res.data.records_count}, function() {
//                 this.appointmentlist();
//               });
//           }
//         }); 
// }




Confirmstatus = (confirmstatus) => {
  if(confirmstatus == 'yes'){
   // this.deleteappointment();
    $('.showconfirm').removeClass('show');
  }else{
    $('.showconfirm').removeClass('show');	
    $('.showconvey').removeClass('show');	
  }
    
}
exportdata(){
	let stat = '';		
        var qs = require('qs');
        var postObject = {
          admin_id : localStorage.getItem("admin_id"),
          driver_id : this.state.driverid
        };
        axios.post(apiUrl+"Driver/exportAppt",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
            var url = res.data.url;
            var cleanurl = url.replace(/([^:]\/)\/+/g, "$1");
            window.open(cleanurl, '_blank', 'noreferrer');
            // window.location.reload();
          }
        }); 
      
}

  appointmentlist() {

    var appointmentlist = this.state.appointmentlist;
    if (appointmentlist != "undefined" && appointmentlist != null) {
      if (appointmentlist.status == "success") {
        if (Object.keys(appointmentlist).length > 0) {
          const appointmentlistDetails = appointmentlist.appointmentlist.map(
            (appointmentlist, Index) => {
              let sno = Index+1;
              if(appointmentlist.app_status == 'A'){
                var status = 'active';
              }else{
                  status = 'inactive';
              }

              var track_activity='';
              if(appointmentlist.track_activity == 2){
                var track_activity = 'Forwarded';
              }else if(appointmentlist.track_activity == 3){
                var track_activity = 'Cancel';
              }else if(appointmentlist.track_activity == 4){
                var track_activity = 'In Progress';
              }
              else if(appointmentlist.track_activity == 5){
                var track_activity = 'Assigned';
              }else if(appointmentlist.track_activity == 6){
                var track_activity = 'Observation';
              }
              else if(appointmentlist.track_activity == 7){
                var track_activity = 'Driver Accepted';
              }else if(appointmentlist.track_activity == 8){
                var track_activity = 'Driver Declined';
              }
              
              // var mobile = ((appointmentlist.phone == null)||(appointmentlist.phone == '')) ?  appointmentlist.phone = 'N/A' : appointmentlist.phone;
              // var year_of_started = ((appointmentlist.year_of_started == null)||(appointmentlist.year_of_started == '')) ?  appointmentlist.year_of_started = 'N/A' : appointmentlist.year_of_started;
              return (
                <tr key={appointmentlist.appointment_id}>
                <td>{sno}</td>
				 <td>
                  {appointmentlist.appointment_code}
                 
                  </td>
                  <td>{appointmentlist.patient_name}{(appointmentlist.gender!="" && appointmentlist.gender!="NULL")? " | "+appointmentlist.gender:''}{(appointmentlist.age!="" && appointmentlist.age!="0")? " | "+appointmentlist.age:''}</td>
                  {/* <td>{gender}</td> */}
                  <td>{appointmentlist.purpose_to_book!=null?appointmentlist.purpose_to_book:'N/A'}</td>
                  <td>{appointmentlist.schedule!=null?moment(appointmentlist.schedule).format("MM/DD/YYYY, h:mm A"):'N/A'}</td>
                  
                 
                 
                 
                  {/* <td>{appointmentlist.domain_name}</td> */}

				        
                 
				        
                  <td>{lang.Capitalize(status)}{track_activity!=""? " | "+track_activity:''}</td>                  
                </tr>
              );
            }
          );
          return appointmentlistDetails;
        }
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
    }
  }
  
  render() {
    return (
      <div className="wrapper"> 
 <div className="confirm-action showconfirm">
			<p>Are you sure! you want to {this.state.setmessage}?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
    <div className="confirm-action showconvey">
			<p>Rehab center not available on this appointment</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">OK</a>
		</div>
    <Header />
    <MenuSidebar currentpage="drivers" />  

  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">
            <h3>Appointments Of {this.state.driver}</h3>
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>     
                <div className="add_button">  
      		 
                  {/* <a className="" href='/user-import'>Import <i className="import-icon icon" aria-hidden="true"></i> &nbsp;|&nbsp;</a>*/}
                  <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i>  &nbsp;|&nbsp;</Link>
                  <a className="" href={'/drivers-view/'+this.state.driverid}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>
              </div>     
        </div>

        <div className="car-listing-row">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
			          <th>Appt ID</th>
                <th>Patient</th>
                
                <th>Purpose</th>
                <th>Scheduled On</th>
                {/* <th>Domain</th> */}

           
                
                <th>Status</th>
                
              </tr>
            </thead>
            <tbody>{this.state.renderfunction}</tbody>
          </Table>
          <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>
        </div>
                      
      </div>  
    </div>  
  </div>
      
    </div>
    );
  }
}

export default withRouter(ViewApptsList);