import React, { Component } from 'react';
import { useParams, useNavigate  } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth } from "../Helpers/SettingHelper";
import { apiUrl,tapURL} from'../Config/Config';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
	
	return (
	  <WrappedComponent
	  {...props}
	  params={params}
	  navigate ={navigate}
	  // etc...
	  />
	);
	};

class Edit extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
		const tabId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

		this.state = {
            tabId: tabId,
			tab_name:'',
			description:'',
			title:'',
			content:'',
			Loading:false,
			selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			selectedtype : '',
			selectedtypeValue : '',
			editid:0,
			image_preview:'',
			image_name:'',
			image:'',
			tabFolder:'',
			old_image:'',
			fileExtension:'',
		};
		// this.props.getCategoryDetailList(tabId);
		var qs = require('qs');
		var catid = this.state.tabId;
		var postObject = {
			id : this.state.tabId,
			admin_id : localStorage.getItem("admin_id"),
		};
		axios.post(apiUrl+'tabmanagement/listdetail',qs.stringify(postObject))
		.then(res => {
		// axios.get(apiUrl+"tabmanagement/listdetail?id="+catid).then(res => {
			this.setState({tab_name:res.data.tablist.name,description:res.data.tablist.description,title:res.data.tablist.title,content:res.data.tablist.content});
			if(res.data.tablist.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
			if(res.data.tablist.content_type !=''){
				this.setState({selectedtype:{value: res.data.tablist.content_type, label: lang.Capitalize(res.data.tablist.content_type)}});
			}
			this.setState({selectedtypeValue:res.data.tablist.content_type,editid:1,image_preview:res.data.tablist.image,tabFolder:res.data.tabfolder,old_image:res.data.tablist.image})
		}); 
		
		this.handleInputChange = this.handleInputChange.bind(this);
	    this.handleChange = this.handleChange.bind(this);

    }
	handleTypeChange = selectedOption => {
        this.setState({selectedtype:selectedOption})
        this.setState({  selectedtypeValue : selectedOption.value});
    };
    handleChange = selectedOption => {

        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
   handleInputChange(event) {
      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'tab_trending'){
      	this.setState({tab_trending_checked: !this.state.tab_trending_checked});
      }  		
    }

	componentDidMount() {
		document.title = PageTitle('Tab Edit');
    }

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		  this.setState({
			image_name: file.name,
			image_preview: reader.result,
			image: file,
			fileExtension:extension,editid:0
		  });
		};
		reader.readAsDataURL(file);
	}
        handleFormSubmit = () => {
			if(this.validateForm()){ 
				this.setState({Loading:true});
				
				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}

				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					tab_name: formPayload.tab_name,
					title : formPayload.title,
					tab_description: formPayload.description,
					content_type: formPayload.selectedtypeValue,
					// content: formPayload.content,
					status:  status,
					editid:  this.state.editid,
					old_image:  this.state.old_image,
					image: this.state.editid===0 ? formPayload.image:'',
					id: formPayload.tabId,
				
				};
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}		       
				axios.post(apiUrl+"tabmanagement/edit",formData ).then(res => {
					if(res.data.status === "success"){	
						this.setState({Loading:false});
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
								setTimeout(
								function() {
									$('.success_message').html('');
								}
								.bind(this),
								3000
								);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}
				});
			}
	}

	validateForm() {
		//const {admin_company_name,admin_fname} = this.state;
		console.log(this.state.image,"selectedtypeValue")
		const {tab_name,title,description,selectedtypeValue,image} = this.state;
		let errors = {};
      	let formIsValid = true;
		
		  if (!tab_name) {
			formIsValid = false;
			$('.errortab_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(tab_name){
			if (/^[a-zA-Z\s]*$/.test(tab_name)) {
			$('.errortab_name').html('');
			}else{
				formIsValid = false;
				$('.errortab_name').html('Please enter valid tab name');
			}
		}
		
		if (!title) {
			formIsValid = false;
			$('.errortab_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(tab_name){
			$('.errortab_title').html('');
		}

		if (!description) {
			formIsValid = false;
			$('.errordescription').html('<span class="errorspan">Please fill the field</span>');
		}else if(description){
			$('.errordescription').html('');
		}
		if (!selectedtypeValue) {
			formIsValid = false;
			$('.errorcontenttype').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedtypeValue){
			$('.errorcontenttype').html('');
		}

		if (this.state.editid==0) {
			if (!image) {
				
				formIsValid = false;
				$('.errorfile').html('<span class="errorspan">Please fill the feild</span>');
					}else{
						$('.errorfile').html('');
					}
					if (image) {		
			if (!image.name.match(/\.(jpg|jpeg|png|svg)$/)) {
				
				formIsValid = false;
					 $('.errorfile').html('<span class="errorspan">Please select valid type</span>');
				   
			  }else{
				$('.errorfile').html('');
			  }
			}
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }


  render() {


  	  const {selectedOption,selectedtype,tabFolder} = this.state;


		if(this.state.editid==1){
			
		console.log(1)
		var loaderimg = tapURL + '/'+tabFolder+ '/'+this.state.image_preview;
		var preview = <img className="img_class" src={loaderimg} alt="" />;
	}else {
	
		
		console.log(2)
		var	preview = <img className="img_class" src={this.state.image_preview} alt="" />;

	}
		


  	
    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="tabmanagement" />  
      <div className="content">	
		<div className="content-wrapper">
		<div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">
			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/tabmanagement">Back</a>
			</div>
			<div className="title">
				<h4>Edit Details</h4>
			</div>
			 <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label> Name<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="tab_name" onChange={this.handleInputChange} className="form-control" value={this.state.tab_name} />
					<div className="errortab_name"></div>
				</div>
				<div className="default-font">
				<div className="form-group">					
					<label>Content Type<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedtype}
                            options={lang.common.contentType_option} 
                            onChange={this.handleTypeChange}
                            />
							<div className="errorcontenttype"></div>
				</div>
				</div>

				<div className="form-group">
					<label>Image (jpeg,jpg,png,svg)<span class="required" style={{ color: "red" }} >*</span></label>
					<div className="choose-file">
					{this.state.isHidden && <div className="image_success"></div>}
					<span className="profile_btn"><input type="file" name="file" onChange={this.onChangeHandler} /></span>
					</div>
					{preview}
					{/* {loader_image_preview?<a href="javascript:void(0);">X</a>:''} */}
					<div className="errorfile"></div>  
				</div> 
				
			</div>
                  
			<div className="form-right">
			<div className="form-group">
					<label>Title<span class="required" style={{ color: "red" }} >* </span> : </label>
				    <input type="text" name="title" onChange={this.handleInputChange} className="form-control" value={this.state.title}/>
					<div className="errortab_title"></div>
				</div>
				<div className="form-group">					
					<label>Description<span class="required" style={{ color: "red" }} >* </span> : </label>
						<textarea type="text" name="description" onChange={this.handleInputChange} className="form-control" value={this.state.description}  />
					<div className="errordescription"></div>
				</div>
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >* </span> :</label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
	           </div> 
				{/* <div className="form-group">					
					<label>Content:</label>
						<textarea type="text" name='content' onChange={this.handleInputChange} className='form-control' value={this.state.content} />
					<div className="errorcontent"></div>
				</div> */}


			</div>	
			</div>		



			<div className="btn-group export">	
				  <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true?
                                        <span>updating</span>:<span>Submit</span>
                                        }
                   </button>
			</div>
			</form>
			</div>				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


// const mapStateTopProps = (state) => {
//   return {
//     categorydetails: state.categorydetails,
//     categoryedit: state.categoryedit
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getCategoryDetailList: (tabId) =>{
//        dispatch({ type: GET_CATEGORYDETAILLIST,tabId });
//     },
//     getEditCategory: (formPayload) =>{
//        dispatch({ type: GET_EDITCATEGORY, formPayload});
//     }
//   }
// }
export default (withRouter(Edit));