import React, { Component } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
/* import { GET_VIEWrehablist } from '../../actions'; */
import { apiUrl,rehabURL,mediaUrl} from'../Config/Config';
import axios from 'axios';
import moment from 'moment';


import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const rehabid = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;

    this.state = {      
      rehabid: rehabid,
      rehablistview:'',
      amenities:'',
      galleries:[],
     
    };

    axios.get(apiUrl+"rehab/listdetail?id="+rehabid).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.rehablist;
			if(formdata.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
				this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

			}
			this.setState({rehablistview:res.data.rehablist});
			this.setState({membership_details:res.data.membership_details});
			this.setState({galleries:res.data.galleries});
			this.setState({amenities:res.data.amenities!=''?res.data.amenities:'N/A'});
						
			}
			
		}); 
  }

  planlist() {
    console.log(this.state.membership_details,'famlist');
    var planlist = this.state.membership_details;
    if (planlist != "undefined" && planlist != null) {
      //if ( == "success"){
        if (Object.keys(planlist).length > 0) {
          const planlistDetails = planlist.map(
            (planlist, Index) => {
              let sno = Index+1;
              return (

              
                <tr key={planlist.id}>
				         <td>{sno}</td>
                   <td>
                  
                  {moment(planlist.created_date).format('DD MMM YYYY, h:mm A')}
                  </td>
                  <td>
                  {planlist.title} { ' - '+planlist.paid_amount}/{planlist.period=='month'?'m':'y'}
                  </td>
                  {/* <td>
                  {planlist.paid_amount}/{planlist.period=='month'?'m':'y'}
                  </td>
                 */}
                  <td>
                  {planlist.current_status=='A'?'Active':'Inactive'}
                  </td>
                 
                 
                 
                </tr>
              );
            }
          );
          return planlistDetails;
        }else {
          return (
            <tr>
              <td colspan="5" className="v-align-nr">
                No Result
              </td>
            </tr>
          );
        }
      
    }else {
      return (
        <tr>
          <td colspan="4" className="v-align-nr">
            No Result
          </td>
        </tr>
      );
    }
  }
   componentDidMount() {
     document.title = PageTitle('User View');
   }

   componentDidUpdate(prevProps,prevState){

   }
   componentWillReceiveProps(Props){
   
    
   }
   
  render() {
    
  	const {galleries} = this.state;

    var mobileno = ((this.state.rehablistview.phone === null)||((this.state.rehablistview.phone === ''))) ?  this.state.rehablistview.phone = 'N/A' : this.state.rehablistview.phone;

    var startedyear = ((this.state.rehablistview.year_of_started === null)||((this.state.rehablistview.year_of_started === ''))) ?  this.state.rehablistview.year_of_started = 'N/A' : this.state.rehablistview.year_of_started;
    if(this.state.rehablistview.status == 'A'){
      var status = 'Active';
    }else{
        var status = 'Inactive';
    }
    if(this.state.rehablistview.period == 'Month'){
      var period = 'm';
    }else{
        var period = 'y';
    }
    if(this.state.rehablistview.current_status == 'A'){
      var current_status = 'Active';
    }else{
        var current_status = 'Inactive';
    }
    if(this.state.rehablistview.period ===null){
      var period = 'm';
    }
    if(this.state.rehablistview.paid_amount ===null){
      var paid_amount = 500;
    }else{
      var paid_amount = this.state.rehablistview.paid_amount;
    }
    if(this.state.rehablistview.title ===null){
      var title = 'Platinum';
    }else{
      var title = this.state.rehablistview.title;
    }
    
    var loaderimg = rehabURL+'/' +this.state.rehablistview.profile_image;
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="rehabs" />  
      
      <div className="content"> 
        <div className="content-wrapper">
         <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                {/* <div className="title">
                  <h3>Rehab Details</h3>
                
                </div> */}
                <div className="listing-header"> 
                  <div className="title">
                    <h3>Rehabs Details</h3>
                  </div>
                    
                  <div className="add_button">  
                  <a className="" href='/rehabs'><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>              
                  </div>
                </div>
           
                <div className="form-row">
                    <div className="details_left">
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Rehab Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.rehablistview.center_name}</p>
                          </div>
                        </div>
                        {/* <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Profile Image<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                         
		                          <img className="img_class" src={loaderimg} alt="" />
                          </div>
                        </div> */}
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Description<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.rehablistview.description!=null?this.state.rehablistview.description:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.rehablistview.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{mobileno}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Address<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.rehablistview.center_address!="null"?this.state.rehablistview.center_address+', ':''}{this.state.rehablistview.city!="null"?this.state.rehablistview.city+', ':''}{this.state.rehablistview.country!="null"?this.state.rehablistview.country+', ':''}{this.state.rehablistview.zipcode!="null"?this.state.rehablistview.zipcode:''}</p>
                          </div>
                          
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Year of Started<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{startedyear}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Amenities<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.amenities}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Domain<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.rehablistview.domain_name}</p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Count of appointments<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{this.state.rehablistview.appt_count}  | 
                            <Link to={{ pathname: '/rehabs-appt-view/'+this.state.rehablistview.rehab_id+'/'+this.state.rehablistview.center_name}} className="" title="View">  <i className="ft-eye" aria-hidden="true"></i></Link>
                            </p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{status}</p>
                          </div>
                        </div>


                    </div>
                </div>

                
                <div className="title">
                  <h3>Galleries</h3>
                </div>
               
                <div className="form-row">
                <div className="details_left">
                <div className="view-details">
                {galleries.map((item) => (
          <div key={item.rehab_gallery_id}>
            <img width="100px" height="100px" src={mediaUrl+item.image_path+'/'+item.image} alt={`Image ${item.rehab_gallery_id}`}  />
            <br></br>
			
          </div>
        ))}
                        </div>
                  </div>
                  </div>

                  {/* <div className="title">
                  <h3>Address Details</h3>
                </div>
                <div className="form-row">
                <div className="details_left">
                <div className="view-details">
                          <div className="view-details-one">
                              <p><b> Street Address<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.rehablistview.center_address!="null"?this.state.rehablistview.center_address:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>City<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                       
                          <p>{this.state.rehablistview.city!="null"?this.state.rehablistview.city:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>State<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.rehablistview.state!="null"?this.state.rehablistview.state:'N/A'}</p>

                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Country<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                          <p>{this.state.rehablistview.country!="null"?this.state.rehablistview.country:'N/A'}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Zip Code<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                        
                          <p>{this.state.rehablistview.zipcode!="null"?this.state.rehablistview.zipcode:'N/A'}</p>

                          </div>
                        </div>
                        </div>
                        </div> */}
                  
                        <div className="title">
                    <h3>Membership Details
</h3>
                  </div>
                  <div className="form-row">
                  <div className="car-listing-row">
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Subscribed On</th>
                            <th>Package</th>
                            {/* <th> Amount/Period</th> */}
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>{this.planlist()}</tbody>
                      </Table>
                      {/* <div className="text-center">
                        <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalRecord}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        />
                      </div> */}
                    </div>
                </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}
export default withRouter(View);
