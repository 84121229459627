import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl} from'../Config/Config';
import axios from 'axios';
const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Add extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			fontname:'',
			fontfamilyname:'',
			fonturl:'',
			font_file: [],
			status:'',
		    selectedFile: null,
			Loading:false,
			selectedvalue: '',
            selectedOption:{ value: 'active', label: 'Active'},
			maintabFontSize : '',
			menuFontSize : '',
			primaryFontSize : '',
			letterSpacing : '',
			lineHeight : '',
			font_type:'google_font',
			fontweight:'',
			file_name: '',
			file_preview: '',
			fontFile: '',
			file_fileExtension:'',

		};
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.handleChange = this.handleChange.bind(this);
    }
	

   handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
    }

     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	componentDidMount() {
		document.title = PageTitle('Font Add');
    }

  /*onFileChange = event => { 
     
      // Update the state 
      this.setState({ selectedFile: event.target.files[0] }); 
	  
	  this.fileObj = [];
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
        this.setState({ font_file: this.imageArray })	
		
     
    }; */
	
	// onFileChange = (e) =>{
	// this.fileObj = [];
    //     this.fileObj.push(e.target.files)
    //     for (let i = 0; i < this.fileObj[0].length; i++) {
    //         this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
    //         //this.fileArray.push(this.fileObj[0][i])
    //         this.imageArray.push(this.fileObj[0][i])

    //     }
    //     this.setState({ font_file: this.imageArray })		
    // }

	onChangeHandler=event=>{
		let reader = new FileReader();
		const file = event.target.files[0];
		const extension = file.name.split('.').pop();
		reader.onloadend = () => {
		this.setState({
			file_name: file.name,
			file_preview: reader.result,
			fontFile: file,
			file_fileExtension:extension,
		});
		};
		reader.readAsDataURL(file);
	}	

	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				var admin_id = localStorage.getItem("admin_id");
				var postObject = {
					admin_id     : admin_id,
					font_name    : formPayload.fontname,
					fonturl    : formPayload.fonturl,
					font_family_name    : formPayload.fontfamilyname,
					selectedFile : formPayload.selectedFile,
					status : status,
					fontweight : formPayload.fontweight,
					maintabFontSize : formPayload.maintabFontSize,
					menuFontSize : formPayload.menuFontSize,
					primaryFontSize : formPayload.primaryFontSize,
					letterSpacing : formPayload.letterSpacing,
					lineHeight : formPayload.lineHeight,
					font_type:formPayload.font_type,
				};

				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
				
				// var filesdata = this.state.font_file;
				// for (var i in filesdata) {
				//  formData.append('font_file[]',filesdata[i])
				// }	
				
				var filesdata = formPayload.fontFile;
				formData.append('font_file',filesdata);	
				
				axios.post(apiUrl+"font/add",formData).then(res => {
					if(res.data.status === "success"){
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');


								setTimeout(
								function() {
									$('.success_message').html('');
									this.props.navigate('/fonts');
								}
								.bind(this),
								3000
								);
					} else {
						this.setState({ Loading: false });
						$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
						
							setTimeout(
							function() {
								$('.success_message').html('');
							}
							.bind(this),
							3000
							);
					}
				});
			}
	}

	validateForm() {

		const {fontname,fontfamilyname,selectedFile,fonturl,fontweight,maintabFontSize,menuFontSize,primaryFontSize,font_type,font_file,letterSpacing,lineHeight, file_name} = this.state;

		let errors = 0;
	
		if (!fontname) {
			errors++;
			$('.errorfont_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfont_name').html('');
		}

		if (font_type == 'google_font') {
			if (!fonturl) {
				errors++;
				$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorfonturl').html('');
			}
		}else if(font_type == 'normal_font'){
			// if(font_file.length==0){
			// 	errors++;
			// 	$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			// }else{
			// 	$('.errorfile').html('');	
			// }
			if(!file_name){
				errors++;
				$('.errorfile').html('<span class="errorspan">Please upload files</span>');
			}else{
				$('.errorfile').html('');	
			}

			if (!fontweight) {
				errors++;
				$('.errorfontweight').html('<span class="errorspan">Please fill the field</span>');
			}else{
				$('.errorfontweight').html('');
			}
		}
		
		if (!fontfamilyname) {
			errors++;
			$('.errorfontfamilyname').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfontfamilyname').html('');
		}

		/*if (!fonturl) {
			errors++;
			$('.errorfonturl').html('<span class="errorspan">Please fill the field</span>');
		}else if(fontname){
			$('.errorfonturl').html('');
		}*/


		/*if (selectedFile === null || selectedFile === '') {
		$('.errorfile').html('<span class="errorspan">Please choose the font file</span>');
		errors++;
		}else if(selectedFile){
	    var filePath = selectedFile.name;
	    var allowedExtensions =  
                    /(\.ttf)$/i; 
        if(!allowedExtensions.exec(filePath)){
        	errors++;
		    $('.errorfile').html('<span class="errorspan">Please choose only ttf format</span>');
        }else{
            $('.errorfile').html('');
        } 
        }else{             
		$('.errorfile').html('');
		}*/

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

    componentWillReceiveProps(Props){

    	 	if(Props.fontadd !== this.props.fontadd){
    		if(Object.keys(Props.fontadd).length > 0){
				this.setState({Loading:false});
					if(Props.fontadd[0].status === "success"){
		            	$('.success_message').html('<div class="status_sucess"><h3>'+Props.fontadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
							this.props.navigate('/fonts');
						}
						.bind(this),
						3000
						);
					}else{
						$('.success_message').html('<div class="status_sucess"><h3>'+Props.fontadd[0].message+'</h3></div>');
						setTimeout(
						function() {
							$('.success_message').html('');
						}
						.bind(this),
						3000
						);
					}

    		}
    	}
      }

  render() {
  	const {selectedOption,font_type} = this.state;
	  var	preview = (this.state.file_preview) ? 
	//   <img className="img_class" src={this.state.file_preview} alt="" /> :'';
	  <img className="img_class" src={mediaUrl+'font1.png'} alt="" /> :'';

    return (
      <div className="wrapper"> 
 
	  <Header />
    <MenuSidebar currentpage="fonts" />  

	<div className="content">	
		<div className="content-wrapper">
				  <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/fonts">Back</a>
			</div>
			<div className="title">
				<h4>Add Font</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			   	<div className="form-left">
					<div className="form-group">
						<label>Font Name<span class="required" style={{ color: "red" }} > * </span></label>
						<input type="text" name="fontname" onChange={this.handleInputChange} className="form-control" value={this.state.fontname} autoComplete="off" />
						<div className="errorfont_name"></div>
					</div>
					
					{/* <div className="form-group">
						<label>Font Import url</label>
						<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
						<div className="errorfonturl"></div>
					</div> */}
					<div className="form-group">					
						<label>Font type<span class="required" style={{ color: "red" }} > * </span> :</label><br />
						Google Font <input type="radio" name='font_type' value="google_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'google_font') ? 'checked' : '' } /> <br />
						Normal Font<input type="radio" name='font_type' value="normal_font" onChange={this.handleInputChange}  checked={(this.state.font_type === 'normal_font') ? 'checked' : '' } /><br />
						
						<div className="error_font_type"></div>
					</div> 

					<div className={this.state.font_type == 'google_font'?"show-font-type":"hide-font-type"}>
						<div className="form-group">
								<label>Font Import url<span class="required" style={{ color: "red" }} > * </span> :</label>
								<textarea className="form-control" name="fonturl" onChange={this.handleInputChange}  value={this.state.fonturl} id="" rows="5"  autoComplete="off" />
								<div className="errorfonturl"></div>
							</div>
					</div>
					<div className={this.state.font_type == 'normal_font'?"show-font-type":"hide-font-type"}>
						<div className="form-group">
							<label>Font<span class="required" style={{ color: "red" }} > * </span> :</label>
							<input type="file" onChange={this.onChangeHandler} /> 
							{preview}
							{this.state.file_name}
							<div className="errorfile"></div> 
						</div>
					</div>	
					<div className="form-group">
						<label>Main Tab Font Size</label>
						<input type="text" name="maintabFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.maintabFontSize} autoComplete="off" />
						<div className="errormaintabFontSize"></div>
					</div>
					<div className="form-group">
						<label>Primary Font Size</label>
						<input type="text" name="primaryFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.primaryFontSize} autoComplete="off" />
						<div className="errorprimaryFontSize"></div>
					</div>
					<div className="form-group">
						<label>Line Height</label>
						<input type="text" name="lineHeight" onChange={this.handleInputChange} className="form-control" value={this.state.lineHeight} autoComplete="off" />
						<div className="errorlineHeight"></div>
					</div>
				</div>				
				<div className="form-right">
					<div className="form-group">
						<label>Exact Family Name<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="fontfamilyname" onChange={this.handleInputChange} className="form-control" value={this.state.fontfamilyname} autoComplete="off" />
						<div className="errorfontfamilyname"></div>
					</div>
					<div className="form-group">
						<label>Font Weight :</label>
						<input type="text" name="fontweight" onChange={this.handleInputChange} className="form-control" value={this.state.fontweight} autoComplete="off" />
						<div className="errorfontweight"></div>
					</div>
					<div className="form-group">
						<label>Menu Font Size</label>
						<input type="text" name="menuFontSize" onChange={this.handleInputChange} className="form-control" value={this.state.menuFontSize} autoComplete="off" />
						<div className="errormenuFontSize"></div>
					</div>
					<div className="form-group">
						<label>Letter Spacing</label>
						<input type="text" name="letterSpacing" onChange={this.handleInputChange} className="form-control" value={this.state.letterSpacing} autoComplete="off" />
						<div className="errorletterSpacing"></div>
					</div>
					{/* <div className="form-group" style={{display:"none"}}>
						<label>Font</label>
					<input type="file" onChange={this.onFileChange} multiple /> 
					<div className="errorfile"></div> 
					</div> */}
					<div className="form-group">					
						<label>Status</label>
						<Select 
						value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
						options={lang.common.status_option} 
						onChange={this.handleChange}
						/>
					</div>
				</div>
			</div>		

				<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true && <span className="load-data"></span> }Submit
                   </button>
				</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}



export default withRouter(Add);