import React, { Component } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import axios from 'axios';
import { apiUrl, mediaUrl,carImageUrl, currency, dummycarUrl} from'../Config/Config';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import MyEditor from '../Membership/MyEditor';
import { Scrollbars } from 'react-custom-scrollbars';

const initialState = {
			
			firstname:'',
			lastname:'',
			email:'',
			phoneno:'',
			status:'',
			
            selectedOption:{ value: 'active', label: 'Active'},
			selectedvalue:'',
			domainlist:[],
			selectedDomainOption:'',
			selectedDomainvalue:'',
			domainlistlength:'',
			maxCharacterLimit : 20,
			
			street:'',
			city:'',
			state:'',
			country:'',
			postalcode:'',
		
			selectedRehabvalue:'',
			
		};
		const withRouter = WrappedComponent => props => {
			const params = useParams();
			const  navigate = useNavigate();
			// etc... other react-router-dom v6 hooks
		  
			return (
			  <WrappedComponent
				{...props}
				params={params}
				navigate ={navigate}
				// etc...
			  />
			);
		  };
class Add extends Component {

	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state =  { ...initialState }
		
	    this.handleInputChange = this.handleInputChange.bind(this);
	  
       this.handleChange = this.handleChange.bind(this);
	  

    }
	 handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
     
    }
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	
	handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
}
handleRehabChange = selectedRehabOption => {
		
	this.setState({selectedRehabOption:selectedRehabOption})
	this.setState({  selectedRehabvalue : selectedRehabOption.value});

}

   isUSZipCode(zipCode) {
    // Regular expression pattern for validating US ZIP codes
    const zipCodePattern = /^\d{5}(-\d{4})?$/;
    return /^\d{5}(-\d{4})?$/.test(zipCode) || /^\d{9}$/.test(zipCode);
}
	componentDidMount() {
		var  selecteddomainOption ='';
		var selectedDomainvalue ='';
		document.title = PageTitle('Add rehab');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		let domainlist = [];
		axios.post(apiUrl+"user/domainlist").then(res => {
			if(res.data.status == "success"){
				let i=0;
				let domainlists = res.data.domainlist;
		
				for(i; i<domainlists.length; i++){
					
					domainlist[i] = { value: domainlists[i]['domain_id'], label: domainlists[i]['domain_name'] };
				}
				
				if(res.data.domainlist.length==1){
				let	j=0;
					for(j; j<res.data.domainlist.length; j++){
						
					 selecteddomainOption = { value:domainlists[j]['domain_id'], label: domainlists[j]['domain_name'] };
				    selectedDomainvalue = domainlists[j]['domain_id'];	
				}
					
					this.setState({selectedDomainOption:selecteddomainOption})
					this.setState({selectedDomainvalue:selectedDomainvalue})
				}

			}       
		});
		
		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'rehab/getamenitynamelist?admin_id='+admin_id)
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						amenitynamelist:res.data.amenitynamelist
					});
				
				}else{
				}
			});
			
		
			var qs = require('qs');
			var admin_id = localStorage.getItem('admin_id');

			var postobject = {
				admin_id: admin_id
			};
		
			let rehablists = [];
			axios.post(apiUrl+"rehab/rehablist",qs.stringify(postobject)).then(res => {
				if(res.data.status == "success"){
					let i=0;
					let rehablist = res.data.rehablist;
					
					for(i; i<rehablist.length; i++){
						rehablists[i] = { value: rehablist[i]['rehab_id'], label: rehablist[i]['center_name'] };
					}
					this.setState({
						rehablists:rehablists
					});
				
				}       
			});
    }
	

	
	 handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				var qs = require('qs');
				const formPayload = this.state;
			    var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				
				var postObject = {
			        admin_id : localStorage.getItem("admin_id"),
					userid       : formPayload.userid,
					domain_id       : formPayload.selectedDomainvalue,
					rehab_id    : formPayload.selectedRehabvalue,
					firstname    : formPayload.firstname,
					lastname     : formPayload.lastname,
					email        : formPayload.email,
					phoneno      : formPayload.phoneno,
					street      : formPayload.street,
					city      : formPayload.city,
					state      : formPayload.state,
					country      : formPayload.country,
					postalcode      : formPayload.postalcode,
					

					status       :  status,
				};
						
				let formData = new FormData();
				formData.append('selectedYear', formPayload.selectedYearvalue);
				console.log(formData,"formDataformData")
					
					
				axios.post(apiUrl+"driver/add",qs.stringify(postObject)).then(res => {

				 
					if(res.data.status === 'success'){
					   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   this.setState({
							username:'',			
							firstname:'',
							lastname:'',
							email:'',
							phoneno:'',
							
						   });
						   setTimeout(
						   function() {
					// 		var galleryimg = 'No';
					// if(Object.keys(formPayload.selectedimages).length >0){
	                //    galleryimg = 'Yes';
					// }
							   $('.success_message').html('');
									this.props.navigate('/drivers');
						   }
						   .bind(this),
						   3000
						   );
					   }else{
						this.setState({Loading:false});
						   $('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
						   setTimeout(
						   function() {
							   $('.success_message').html('');
						   }
						   .bind(this),
						   3000
						   );
					   }
				  }); 
			}
	}

	validateForm() {
		const {selectedRehabvalue,firstname,lastname, email, phoneno,selectedDomainvalue,postalcode} = this.state;

		let errors = 0;
		const nameRegex = /^[A-Za-z\s.]+$/;
		if (!selectedRehabvalue) {
			errors++;
			$('.errorrehab').html('<span class="errorspan">Please fill the field</span>');
		}else if(selectedRehabvalue){
			
				$('.errorrehab').html('');
			}
			
		
		if (!firstname) {
			errors++;
			$('.errorfirstname').html('<span class="errorspan">Please fill the field</span>');
		}else if(firstname){
			if (!nameRegex.test(firstname)) {
				errors++;
			$('.errorfirstname').html('<span class="errorspan">Please enter the valid name</span>');
			}else{
				$('.errorfirstname').html('');
			}
			
		}
		
		if (!selectedDomainvalue) {
		 	errors++;
		 	$('.errorassign_domain').html('<span class="errorspan">Please select the field</span>');
		}else if(selectedDomainvalue){
		 	$('.errorassign_domain').html('');
		 }
		
		
		if (!lastname) {
			errors++;
			$('.errorlastname').html('<span class="errorspan">Please fill the field</span>');
		}else{
				$('.errorlastname').html('');
		}
		
		if (!email) {
			errors++;
			
			$('.erroremail').html('<span class="errorspan">Email is required</span>');
			}else if(email){
			var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
			if (reg.test(email)) 
			{
			  $('.erroremail').html('');
			}else{
			  errors++;
			  
			$('.erroremail').html('<span class="errorspan">Please enter valid email address</span>');
			}
			  
			
			}
		

		if (!phoneno) {
			errors++;
			
			$('.errorphoneno').html('<span class="errorspan">Please fill the field</span>');
		  }else if(phoneno){	
			 var pattern = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
			 var indiapattern =/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
				if (pattern.test(phoneno)) {
				$('.errorphoneno').html('');
				}else if(indiapattern.test(phoneno)){
				$('.errorphoneno').html('');
				}else{
					 errors++;
				
					 $('.errorphoneno').html('<span class="errorspan">Please enter valid contact</span>');
				   }
				}

		// if(postalcode){
		// 	if(this.isUSZipCode(postalcode)){
		// 		$('.errorpostalcode').html('');
		// 	}else{
		// 		errors++;
		// 		$('.errorpostalcode').html('Please enter valid postal code');
		// 	}
		// }
		
		  
			

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

		/*this.setState({
			errors: errors
		});
		return formIsValid;*/
    }

		

    // componentWillReceiveProps(Props){

    // 		if(Props.useradd !== this.props.useradd){
    // 		if(Object.keys(Props.useradd).length > 0){
	// 			this.setState({Loading:false});

	// 			if(Props.useradd[0].status === "success"){

	// 			 $('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
	// 			scrollToTop();
	// 					setTimeout(
	// 					function() {
	// 						$('.success_message').html(this.props.history.push('/rehabs'));
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
	// 				this.setState({username:'',firstname:'',lastname:'',email:'',phoneno:'',user_password:'',user_confirm_password:''})
	// 				}else if(Props.useradd[0].status === "erroremail"){

	// 				 $('.erroremail').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
	// 			     	scrollToTop();
	// 						setTimeout(
	// 						function() {
	// 							$('.erroremail').html('');
	// 						}
	// 						.bind(this),
	// 						3000
	// 						);
	// 				}else if(Props.useradd[0].status === "errorphone"){

	// 				 $('.errorphoneno').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
	// 			     	scrollToTop();
	// 						setTimeout(
	// 						function() {
	// 							$('.errorphoneno').html('');
	// 						}
	// 						.bind(this),
	// 						3000
	// 						);
	// 				}else if(Props.useradd[0].status === "errorusername"){

	// 				 $('.errorusername').html('<span class="errorspan">'+Props.useradd[0].message+'</span>');
	// 			     	scrollToTop();
	// 						setTimeout(
	// 						function() {
	// 							$('.errorusername').html('');
	// 						}
	// 						.bind(this),
	// 						3000
	// 						);
	// 				}else{
	// 					$('.success_message').html('<div class="status_sucess"><h3>'+Props.useradd[0].message+'</h3></div>');
	// 					scrollToTop();
	// 					setTimeout(
	// 					function() {
	// 						$('.success_message').html('');
	// 					}
	// 					.bind(this),
	// 					3000
	// 					);
	// 				}

    // 		}
    // 	}
    // }
	
	
	
	
  



  
  render() {
  	
  	const {selectedRehabvalue,selectedOption,selectedDomainOption,selectedimages,rehablists,selectedRehabOption} = this.state;
	  
				
    return (
      <div className="wrapper"> 


	  <Header />
    <MenuSidebar currentpage="drivers" />  

	
	<div className="content">	
		<div className="content-wrapper">
        <div class="content-wrapper-before"></div>
			<div className="form-wrapper leaa-admin-head card">

			<div className="success_message"></div>
			<div>
				<a class="primary-btn" href="/drivers">Back</a>
			</div>
			<div className="title">
				<h4>Add Driver</h4>
			</div>
		<form className="login" id="login" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
			<div className="form-row">
			<div className="form-left">
			<div className="form-group">
					<label>Rehab</label>
				    
					<Select 
							value={selectedRehabOption?selectedRehabOption:''}
								options={rehablists} 
								onChange={this.handleRehabChange.bind(this)}
								/>
					<div className="errorrehab"></div>
				</div>
				
				<div className="form-group">
					<label>Last Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="lastname" onChange={this.handleInputChange} className="form-control" value={this.state.lastname} />
					<div className="errorlastname"></div>
				</div>
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="phoneno" onChange={this.handleInputChange} className="form-control" value={this.state.phoneno} />
					<div className="errorphoneno"></div>
				</div>
				
				 
				
			   <div className="default-font">
				<div className="form-group">					
					<label>Status<span class="required" style={{ color: "red" }} >*</span></label>
						   <Select 
						   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
                            options={lang.common.status_option} 
                            onChange={this.handleChange}
                            />
				</div>
				</div>
				
			
				</div>
			<div className="form-right">
			
				
				<div className="form-group">
					<label>First Name<span class="required" style={{ color: "red" }} >*</span></label>
				    <input type="text" name="firstname" onChange={this.handleInputChange} className="form-control" value={this.state.firstname} />
					<div className="errorfirstname"></div>
				</div>
				<div className="form-group">
					<label>Email<span class="required" style={{ color: "red" }} >*</span></label>
						<input type="text" name="email" onChange={this.handleInputChange} className="form-control" value={this.state.email} autoComplete="off" />
					<div className="erroremail"></div>
				</div>

				
				<div className="form-group">					
					<label>Assign Domain<span class="required" style={{ color: "red" }} >* </span></label>
						   <Select 
						   value={selectedDomainOption?selectedDomainOption:""}
                            options={lang.common.domainList_option} 
                            onChange={this.handledomainChange}
                            />
							<div className="errorassign_domain"></div>
	           </div> 
				
				
				
					
				 
				</div>
			</div>
			<div className="title">
					<h4> Address</h4>
			</div>

			<div className="form-row">
				<div className="form-left">
	
				<div className="form-group">
					<label>Street</label>
				    <input type="text" name="street" onChange={this.handleInputChange} className="form-control" value={this.state.street} />
					<div className="errorstreet"></div>
				</div>
				<div className="form-group">
						<label>State</label>
						<input type="text" name="state" onChange={this.handleInputChange} className="form-control" value={this.state.state} />
						<div className="errorstate"></div>
					</div>
				
				  
				<div className="form-group">
					<label>Postal Code</label>
				    <input type="text" name="postalcode" onChange={this.handleInputChange} className="form-control" value={this.state.postalcode} />
					<div className="errorpostalcode"></div>
				</div>             
				</div>


				<div className="form-right">
				<div className="form-group">
					<label>City</label>
				    <input type="text" name="city" onChange={this.handleInputChange} className="form-control" value={this.state.city} />
					<div className="errorcity"></div>
				</div>
				<div className="form-group">
					<label>Country</label>
				    <input type="text" name="country" onChange={this.handleInputChange} className="form-control" value={this.state.country} />
					<div className="errorcountry"></div>
				</div> 
					
				</div>

			</div>
			<div className="btn-group export">	
				   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                        <span className="load-data"></span> 
                                        }Submit
                   </button>
			</div>
			</form>
			</div>
				
		</div>	
	</div>
		 	
    </div>
    );
  }
}


export default withRouter(Add);