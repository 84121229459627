/* eslint-disable */
import React, { Component } from 'react';
import { Link,useParams, useNavigate } from 'react-router-dom';
import $ from 'jquery';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import reactCSS from 'reactcss'
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import Select from 'react-select';
import { carImageUrl,apiUrl } from '../Config/Config';
import axios from 'axios';

const withRouter = WrappedComponent => props => {
	const params = useParams();
	const  navigate = useNavigate();
	// etc... other react-router-dom v6 hooks
  
	return (
	  <WrappedComponent
		{...props}
		params={params}
		navigate ={navigate}
		// etc...
	  />
	);
  };
class Gallery extends Component {
  
    fileObj = [];
    fileArray = [];
    imageArray = [];

  constructor(props) {
    super(props);
	this.state = {
		rehab:'',
		rehablist:'',
		selectedOption:'',
		selectedlabel:'',
		selecturltodelete:'',
		selectimagetodelete:'',
		fileList: [],
        currentFolder: null,
		images: []
	}
	var qs = require('qs');	
	var postobject = {
		admin_id:  localStorage.getItem("admin_id")
	};
	this.removeImagebyindex = this.removeImagebyindex.bind(this);
	this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
  }
  
  componentDidMount() {
      document.title = PageTitle('Gallery');

	  var qs = require('qs');
   
      var postobject = {
      admin_id: localStorage.getItem('admin_id')  
      };
	  let rehablist = [];
	  axios.post(apiUrl+"rehab/rehablist",qs.stringify(postobject)).then(res => {
   
		
			if(res.data.status == "success"){
				
				let rehablists = res.data.rehablist;
				rehablist[0] = { value:'All', label: 'All'};
				let i=0;
				for(i; i<rehablists.length; i++){
					rehablist[i] = { value: rehablists[i]['rehab_id'], label: rehablists[i]['center_name'] };
				}
				const newRow = { value: 'all', label: 'All' };
				const newData = [newRow, ...rehablist];

				this.setState({rehablist:newData})
			}
		
	  });
	  var postobject = {
		rehab_id: 'all'  
		};
	  axios.post(apiUrl+"rehab/getFiles",qs.stringify(postobject)).then(res => {
   
		
		if(res.data.status == "success"){
			
            
			this.setState({fileList:res.data.fileList})
		}else{
			this.setState({fileList:[]})

		}
	
  });

	   
   }
   handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    // File size validation (in bytes)
    const maxSize = 1000000; // 28.4 MB

    if (selectedFile.size > maxSize) {
      this.setState({ fileSizeError: true });
      return;
    } else {
      this.setState({ fileSizeError: false });
    }

    // File type validation
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!allowedTypes.includes(selectedFile.type)) {
      this.setState({ fileTypeError: true });
      return;
    } else {
      this.setState({ fileTypeError: false });
    }

    // If both file size and file type are valid, store the selected file
    this.setState({ selectedFile });
  };

  handleFormSubmit = () => {
	if(this.validateForm()){
		const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		};
				  			  
		var filesdata = this.state.user_image;
		let formData = new FormData();
		for (var i in filesdata) {
			formData.append('images[]',filesdata[i])
		}		       
		
		formData.append('rehab_name',this.state.selectedlabel)
		formData.append('rehab_id',this.state.selectedvalue)

		axios.post(apiUrl+"rehab/uploadgallery",formData,config).then(res => {
			if(res.data.status === "success"){
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					this.setState({user_image:'',selectedvalue:'',selectedvalue:'',selectedOption:''})
							setTimeout(
							function() {
								$('.success_message').html('');
							window.location.reload();
							}
							.bind(this),
							3000
							);
							
				}else{
					$('.success_message').html('<div class="status_sucess"><h3>'+res.data.message+'</h3></div>');
					
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
				}
			});
		}
  }
  validateForm() {
	const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];
	const {user_image} = this.state;
	let errors = 0;
	let maxSize = 1000000;//1 mb
	if (!user_image) {
			errors++;
			$('.erroruser_image').html('<span class="errorspan">Please fill the field</span>');
		}else if(user_image.length > 10){
			
				errors++;
				$('.erroruser_image').html('<span class="errorspan">You can only upload up to 10 images</span>');
				//fileErrors.push('You can only upload up to 10 images.');
			 
		}else{
			
	             for (let i = 0; i < user_image.length; i++) 
				 {

					const file = user_image[i];
					const filename = file.name;
					console.log(filename,"filename")

					if (file.size > maxSize) {
						errors++;
					
					$('.erroruser_image').html('<span class="errorspan">'+filename+'File is too large. Max size is 1MB</span>');
					continue;
					}
			
					if (!allowedTypes.includes(file.type)) {
						errors++;
					
					$('.erroruser_image').html('<span class="errorspan"> '+filename+' file type is Invalid.</span>');

					continue;
					}
	            }
	       }
		  
	
		  if(errors>0) {
			setTimeout(function () {
			scrollToTopValidate();
			}, 100);
			return false;
			}
			else {
			return true;
			}
	
			
		}
  componentWillReceiveProps(Props){

		if(Props.gallery !== this.props.gallery){					
			
			if(Object.keys(Props.gallery).length > 0){				
				this.setState({ Loading: false });
				$('.success_message').html('<div class="status_sucess"><h3>'+ Props.gallery[0].message+'</h3></div>');
                	scrollToTop();
    				setTimeout(
						function() {
							$('.success_message').html('');
							window.location.reload();
						}
						.bind(this),
						3000
						);
			}
		}

   }
  



   uploadMultipleFiles = (e) =>{
	this.fileObj = [];

        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
            //this.fileArray.push(this.fileObj[0][i])
            this.imageArray.push(this.fileObj[0][i])

        }
		this.setState({ user_image: this.imageArray })		
    }


     removeImagebyindex = (indexvalue) => {
  	 
	  var list = [...this.imageArray];
	  this.imageArray = [];
	  this.fileArray = [];
	  list.splice(indexvalue, 1);
	 
	  for (let i = 0; i < list.length; i++) {
		this.imageArray.push(list[i]);
		this.fileArray.push(URL.createObjectURL(list[i]));
	  }
	
		this.setState({ user_image: this.imageArray })
		
		
		
	}
	handleChange = (selOption) => {
		var postobject = {
			rehab_id: selOption.value  
			};
			var qs = require('qs');

		axios.post(apiUrl+"rehab/getFiles",qs.stringify(postobject)).then(res => {
   
		
			if(res.data.status == "success"){
				
				this.setState({fileList:res.data.fileList})
			}else{
				this.setState({fileList:[]})

			}
		
	  });
			this.setState({selectedOption:selOption})
			this.setState({  selectedvalue : selOption.value});
			this.setState({  selectedlabel : selOption.label});
		
   };
   renderPreview(file) {
	const { name, url } = file;
	const fileExtension = name.split('.').pop().toLowerCase();
	console.log(fileExtension,file)
	if (fileExtension === 'pdf') {
		return (
			<embed src={url} width="300" height="200" type="application/pdf" title={name} />
		);
	} else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
		return (
			<div>
				<Link onClick={this.confirmPopup.bind(this,url,name)} className="" title="Delete">X</Link>

<img src={url} alt={name} width="300" height="200" />
			 <p>{name}</p>
			</div>
			
		);
	} else {
		// Handle other file types or provide a default preview for unknown types.
		return <p>No preview available for this file.</p>;
	}
}
confirmPopup = (url,name) => {
	this.setState({selecturltodelete: url,selectimagetodelete:name})	

	$('.confirm-action').addClass('show');		
  }
  Confirmstatus = (confirmstatus) => {
	if(confirmstatus == 'yes'){
	  this.deleteimage();
	  $('.confirm-action').removeClass('show');
	}else{
	  $('.confirm-action').removeClass('show');	
	}
	  
  }
  deleteimage(){
  
	
        var qs = require('qs');
        var postObject = {
			selecturltodelete  :this.state.selecturltodelete,
			selectimagetodelete  :this.state.selectimagetodelete,
        };
        axios.post(apiUrl+"rehab/gallerydeleteimage",qs.stringify(postObject)).then(res => {      
          if(res.data.status === 'success'){
			this.setState({fileList:res.data.fileList})
			$('.success_message').html('<div class="status_sucess"><h3> '+ res.data.message +' </h3></div>');
              setTimeout(
                function() {
               
                  $('.success_message').html('');
                   
                }
                .bind(this),
                3000
                );
                
          }
        }); 
}
loadImages = (folder) => {
	apiUrl+"rehab/uploadgallery",
	axios.get(`/listImages/${folder}`)
		.then(response => {
			this.setState({ images: response.data, currentFolder: folder });
		})
		.catch(error => {
			console.error('Error fetching images:', error);
		});
};
  render() {
  /*====Thumb image while choosing from front end===*/
  const {rehablist,selectedOption,fileList, currentFolder} = this.state;

    let imageslist = [];

	let indexlength =  imageslist.length;
	
	const imgThumblist =  this.fileArray.map((url,index) => (
					<li className="thumb" key={indexlength+index}  >
						<span><img src={url}  alt="..." /></span>
						<a href="javascript:void(0);" onClick={this.removeImagebyindex.bind(this,index)}>X</a>
					</li>
				))

 return (
	<div className="wrapper"> 
	 <div className="confirm-action">
			<p>Are you sure! you want to delete this image?</p>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "yes")} className="btn">Yes</a>
			<a href="javascript://" onClick={this.Confirmstatus.bind(this, "no")} className="btn">No</a>
		</div>
		<Header />
		<MenuSidebar currentpage="gallery" />  
			 

		<div className="content">	
			<div className="content-wrapper">
			<div class="content-wrapper-before"></div>
			<div className="car-listing-wrap leaa-admin-head card">
				<div className="content-body">
					<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
						<div className="form-wrapper">
							<div className="success_message"></div>
							<div className="title">
								<h4>Upload image to gallery</h4>
							</div>
							<div className="form-row">
							<div className="form-left">
							<div className="form-group">					
					<label>Rehabs<span class="required" style={{ color: "red" }} > </span></label>
						   <Select 
						   value={selectedOption}
                            options={rehablist} 
                            onChange={this.handleChange.bind(this)}
                            />
							<div className="errorplan"></div>
							
	           </div>
	           </div>
			   <div className="form-left">
								<div className="form-group"> 
										<label>Upload Image<span class="required" style={{ color: "red" }} >* </span>(JPG,SVG,JPEG, PNG, GIF)</label>
											<div className="choose-file">
											{this.state.isHidden && <div className="image_success"></div>}
											<span className="profile_btn">
											<input type="file" className="form-control" name="file" onChange={this.uploadMultipleFiles} multiple /></span>
											</div>
								 <ul className="carimagelisting">
								{/*imageslist*/}
								{imgThumblist}
								</ul>
								<div className="erroruser_image"></div>
								</div>	
								</div>	
								<div>
                {/* <h1>Image Browser</h1>
                <div className="folders">
                    {folders.map((folder, index) => (
                        <div
                            key={index}
                            className="folder"
                            onClick={() => this.setState({ currentFolder: folder })}
                        >
                            {folder}
                        </div>
                    ))}
                </div> */}
                {/* <div className="images">
                    {currentFolder && (
                        // Display images of the selected folder (as in the previous example)
                    )}
                </div> */}
				 <div className="images">
                    {currentFolder && (
                        images.map((image, index) => (
                            <img
                                key={index}
                                src={apiUrl+"rehab/media/gallery/"+{currentFolder}/{image}}
                                alt={image}
                            />
                        ))
                    )}
                </div>
            </div>

						   </div>
						 
							<div className="form-row">
								
								<div className="btn-group right">				
									<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
													<span className="load-data">Loading</span> 
													} Submit
									</button>	
								</div>
								
							</div>


							<div>
                <h1>Gallery Images</h1>
              {fileList.length>0 ? <ul className="gallery-section">
                    {fileList.map((file, index) => (
                        <li key={index}>
                            {/* <p>{file.name}</p> */}
                            {this.renderPreview(file)}
                        </li>
                    ))}
                </ul>:<p>No Images Found</p>}
            </div>
						</div>
					</form>	
				</div>
				</div>
			</div>
		</div>
	</div>
	
		)
  }
}


export default withRouter(Gallery);