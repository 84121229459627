import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
/* import { GET_DASHBOARDLIST  } from '../../actions'; */

class List extends Component {
  
  constructor(props)
  {
    super(props);
    this.state = {
      dashboardlist:'',
      totalRecordrehab:0,
      totalRecordappointments:0,
      totalRecordCompanies:0,
      totalRecordUsers:0,
      totalRecordDrivers:0,      
      totalRecordAssignedAppts:0,
      totalRecordCompletedAppts:0,
      totalRecordTabs:0,
      totalRecordDashboardManage:0,
      totalRecordAmenities:0,
      totalRecordVehicles:0,
      totalRecordRaiseDispute:0,
      totalRecordAds:0,
      totalRecordMembershipCat:0,
      totalRecordMembership:0,
      totalRecordFaqCat:0,
      totalRecordFaq:0,
      totalRecordFonts:0,
      totalRecordTemplates:0,
    };
    
    var qs = require('qs');
    var admin_id = localStorage.getItem("admin_id");
    var loggedas = localStorage.getItem("loggedas");
    var postobject = {
    activePage: 1,
    search_all: '',
    admin_id: admin_id,
    loggedas: loggedas
    };

    axios.post(apiUrl+"dashboard/list",qs.stringify(postobject)).then(res => {
     
      if(res.data.status === 'success'){
        this.setState({ 
          totalRecordUsers : res.data.records_count_users,
          totalRecordCompanies : res.data.records_count_companies,
          totalRecordappointments : res.data.records_count_apponitments,
          totalRecordrehab : res.data.records_count_rehab,

          totalRecordDrivers : res.data.records_count_drivers,
          totalRecordAssignedAppts : res.data.records_count_assigned,
          totalRecordCompletedAppts : res.data.records_count_completed,
          totalRecordTabs : res.data.records_count_tabs,
          totalRecordDashboardManage : res.data.records_count_dashboard,
          totalRecordVehicles : res.data.records_count_vehicle,
          totalRecordAmenities : res.data.records_count_amenities,
          totalRecordRaiseDispute : res.data.records_count_dispute,
          totalRecordAds : res.data.records_count_ads,
          totalRecordMembershipCat : res.data.records_count_membercat,
          totalRecordMembership : res.data.records_count_membership,
          totalRecordFaqCat : res.data.records_count_faqcat,
          totalRecordFaq : res.data.records_count_faq,
          totalRecordFonts : res.data.records_count_font,
          totalRecordTemplates : res.data.records_count_template,

          });
      }
    }); 

    }

   
    componentDidMount() {
   }




  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="dashboard" />
      
      <div className="content"> 
        <div className="content-wrapper">
              <div class="content-wrapper-before"></div>
              <div className="car-listing-wrap dashboard-wrap leaa-admin-head card">

                  <div className="title">
                    <h3>Dashboard</h3>
                  </div>

                  <div className="form-row first">

                        <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge">{this.state.totalRecordUsers}</h4>
                            </div>
                              <a  href="/user" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Users <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>

                        {localStorage.getItem('admin_id') === '1' ?  <div className="form-right">
                            <div className="card pull-up border-top-info-red border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-red card-title-red ">{this.state.totalRecordCompanies}</h4>
                            </div>
                              <a href="/company" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Companies<i className="ft-briefcase float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                       </a>
                    </div>
                          </div>: ''}
                    </div> 

                    <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-yellow card-title-yellow">{this.state.totalRecordrehab}</h4>
                            </div>
                              <a href="/rehabs" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Rehabs<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					  <div className="form-right">
                         <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordappointments}</h4>
                             </div>
                              <a href="/appointments" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Appointments <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div>  
            				</div>

                    <div className="form-row ">
            					<div className="form-left">            					
                        <div className="card pull-up border-top-info-yellow border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge-yellow card-title-yellow">{this.state.totalRecordDrivers}</h4>
                            </div>
                              <a href="/drivers" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Drivers<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>                     
            					<div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordAssignedAppts}</h4>
                          </div>
                          <a href="/appointment-assigned" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Assigned Appts <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>  
            				</div>
                    

                    {/* -------------- */}
                   <div className="form-row ">
            					<div className="form-left">
            					
                        <div className="card pull-up border-top-info-blue border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-blue">{this.state.totalRecordCompletedAppts}</h4>
                            </div>
                              <a href="/appointment-observation" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Completed Appts<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                     
            					 <div className="form-right">
                         <div className="card pull-up border-top-info-black border-top-3 rounded-0">
                            <div className="card-header">
                            <h4 className="card-title card-badge card-title-black">{this.state.totalRecordTabs}</h4>
                             </div>
                              <a href="/tabmanagement" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Tab Management <i className="ft-users float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                            </a>
                          </div>
            					  </div> 
            				</div>
                    <div className="form-row ">
                      <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge">{this.state.totalRecordDashboardManage}</h4>
                          </div>
                          <a  href="/dashboardmanagement" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Dashboard Management <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordAmenities}</h4>
                          </div>
                          <a href="/amenities" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Amenities <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>                                 					
            				</div>

                    <div className="form-row ">
                      <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge">{this.state.totalRecordVehicles}</h4>
                          </div>
                          <a  href="/vehicles" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Vehicles <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordRaiseDispute}</h4>
                          </div>
                          <a href="/raiseDispute" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Raise Dispute <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>                                 					
            				</div>

                    <div className="form-row ">
                      <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge">{this.state.totalRecordAds}</h4>
                          </div>
                          <a  href="/advertisement" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Advertisement<i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordMembershipCat}</h4>
                          </div>
                          <a href="/membershipcategory" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Membership Category <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>                                 					
            				</div>

                    <div className="form-row ">
                      <div className="form-left">
                        <div className="card pull-up border-top-info border-top-3 rounded-0">
                          <div className="card-header">
                              <h4 className="card-title card-badge">{this.state.totalRecordMembership}</h4>
                          </div>
                          <a  href="/membership" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Membership<i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordFaqCat}</h4>
                          </div>
                          <a href="/Faqcategory" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Faq Category <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>                                 					
            				</div>

                    <div className="form-row ">
            					<div className="form-left">
                        <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-orange">{this.state.totalRecordFaq}</h4>
                            </div>
                              <a href="/Faq" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Faq<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>
                      <div className="form-right">
                        <div className="card pull-up border-top-info-purple border-top-3 rounded-0">
                          <div className="card-header">
                            <h4 className="card-title card-badge-purple card-title-purple">{this.state.totalRecordFonts}</h4>
                          </div>
                          <a href="/fonts" className="">
                            <div className="card-content collapse show">
                                <div className="card-body p-1">
                                    <h4 className="font-large-1 text-bold-400">Fonts <i className="ft-users float-right"></i></h4>
                                </div>
                                <div className="card-footer p-1">
                                </div>
                            </div>
                          </a>
                        </div>
            					</div>    
                    </div>

                    <div className="form-row ">
            					<div className="form-left">
                        <div className="card pull-up border-top-info-orange border-top-3 rounded-0">
                            <div className="card-header">
                                <h4 className="card-title card-badge card-title-orange">{this.state.totalRecordTemplates}</h4>
                            </div>
                              <a href="/templates" className="">
                                <div className="card-content collapse show">
                                    <div className="card-body p-1">
                                        <h4 className="font-large-1 text-bold-400">Templates<i className="ft-server float-right"></i></h4>
                                    </div>
                                    <div className="card-footer p-1">
                                    </div>
                                </div>
                          </a>
                        </div>   
            					</div>                        
                    </div>

                      
                </div>
          </div>  
      </div>
      
    </div>
    );
  }
}


export default List;